import { Button, FormInputField } from "@whitespace/components";
import {
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./WastePickupSearch.module.css";
import WastePickupSearchResults from "./WastePickupSearchResults";

WastePickupSearch.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function WastePickupSearch({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return null;
  const { t } = useTranslation();
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { get, error, loading } = useEdp();

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Formik
        initialValues={{ query: "" }}
        onSubmit={async ({ query }, actions) => {
          try {
            const { data } = await get(`/search-waste-pickup?query=${query}`);
            setSearchResults(data);
            setHasSearched(true);
            actions.setSubmitting(false);
          } catch (err) {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <>
            <Form
              action="#"
              method="get"
              onSubmit={handleSubmit}
              className={clsx(styles.form)}
            >
              <fieldset
                disabled={isSubmitting || loading}
                className={clsx(styles.fieldset)}
              >
                <FormInputField
                  name="query"
                  label={t("yourAddress")}
                  type="search"
                  required={true}
                  value={values.query}
                  inputProps={{
                    autoComplete: "street-address",
                    placeholder: "Exempel: Åkerivägen 3",
                    autoFocus: true,
                  }}
                  onInput={() => {
                    if (!values.query.length) {
                      setSearchResults([]);
                      setHasSearched(false);
                    }
                  }}
                />
                <Button
                  type="submit"
                  className={clsx(styles.submitBtn)}
                  disabled={!values.query.length || isSubmitting || loading}
                >
                  {t("get")}
                  <span className={clsx(styles.submitBtnLongText)}>
                    {t("wastePickupSchedule")}
                  </span>
                </Button>
              </fieldset>
            </Form>

            {loading ? (
              <Loading className={styles.loading} />
            ) : error ? (
              <ErrorMessage error={error} />
            ) : (
              hasSearched &&
              values.query.length > 0 && (
                <WastePickupSearchResults searchResults={searchResults} />
              )
            )}
          </>
        )}
      </Formik>
    </div>
  );
}

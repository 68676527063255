import {
  Button,
  Link,
  RoundIcon,
  FormInputField,
} from "@whitespace/components";
import {
  Alert,
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import svaabCustomerContext from "../svaabCustomerContext.js";
import useSvaabApi from "../useSvaabApi.js";

import * as defaultStyles from "./MeterReading.module.css";

MeterReading.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

const schema = yup.object().shape({
  meterReading: yup.number().required(),
});

export default function MeterReading({
  styles = defaultStyles,
  className,
  description,
  ...restProps
}) {
  const [responseInfo, setResponseInfo] = useState("");
  const [postLoading, setPostLoading] = useState(false);
  const [pending, setPending] = useState(pending);
  const [error, setError] = useState(error);
  const [data, setData] = useState({});
  const [showRetryCheckbox, setShowRetryCheckbox] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const { fetch, post } = useSvaabApi();
  const { t } = useTranslation();
  const { selectedSvaabCustomer } = useContext(svaabCustomerContext);
  const canRetryString =
    "Du kan skicka in denna mätarställning om du vet att din vattenförbrukning har ökat.";

  useEffect(async () => {
    if (fetch && !pending && selectedSvaabCustomer) {
      await fetchMeterReading();
    } else {
      setData({});
    }
  }, [fetch, selectedSvaabCustomer?.IDU]);

  const fetchMeterReading = async () => {
    setPending(true);
    try {
      const response = await fetch(`/reading/${selectedSvaabCustomer.IDU}`);
      const { data } = await response.json();
      setData(data);
    } catch (error) {
      let { message } = error;
      setError({ message });
    }
    setPending(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setPostLoading(true);
    try {
      const res = await post(`/reading/${selectedSvaabCustomer.meterID}`, {
        cc: data.cc,
        reading: values.meterReading,
        confirmed: confirmed,
      });
      const json = await res.json();
      const responseData = json.data;
      setResponseInfo(responseData);
      if (responseData?.status === 1) {
        resetForm({ values: "" });
        setShowRetryCheckbox(false);
        await fetchMeterReading();
      } else if (
        responseData?.status === 0 &&
        responseData?.info.endsWith(canRetryString)
      ) {
        setShowRetryCheckbox(true);
      } else {
        setShowRetryCheckbox(false);
      }
    } catch (error) {
      let { message } = error;
      setResponseInfo({ info: message, status: "error" });
    }
    setConfirmed(false);
    setPostLoading(false);
  };

  /**
   * If there are multiple meters on one facitlty, they will share the same IDU.
   * And when we fetch readings for that IDU we will get the readings for all those
   * meters, and it will look like:
   * {
   *    meternumber1: xxxx,
   *    reading1: xxx,
   *    meternumber2: xxxx,
   *    reading2: xxx
   *    ...
   * }
   * so we use this function to find the index for a meter number so we can get the
   * reading for it.
   */
  const getMeterIndex = (data, meterID) => {
    if (!data) {
      return -1;
    }

    let meterIndex = -1;

    Object.entries(data).forEach(([key, value]) => {
      if (key.startsWith("meternumber") && value === meterID) {
        meterIndex = key.charAt(key.length - 1); // The highest index is 9 so the index will always be the last char
      }
    });

    return meterIndex;
  };

  if (pending) {
    return (
      <div className={clsx(styles.component, className)} {...restProps}>
        <Loading />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  if (error) {
    return <ErrorMessage error={error} severity="error" />;
  }

  if (!data.ud) {
    return null;
  }

  const meterIndex = getMeterIndex(data?.ud, selectedSvaabCustomer.meterID);
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {postLoading && <Loading text={t("saving")} className={styles.loader} />}
      {responseInfo && (
        <Alert
          status={
            responseInfo.status !== "error"
              ? responseInfo.status === 1
                ? "success"
                : "info"
              : "error"
          }
          msg={
            responseInfo.status === 1 && !responseInfo.info
              ? t("meterReadingUpdated")
              : responseInfo.info
          }
        />
      )}
      <div className={styles.formWrapper}>
        {description || (
          <Fragment>
            <p>
              {t("meterReadingForMeter")}{" "}
              <b>{data?.ud[`meternumber${meterIndex}`]}</b>
            </p>
            <p>
              {t("currentMeterReading")}{" "}
              <b>{data?.ud[`reading${meterIndex}`]}</b>
            </p>
          </Fragment>
        )}
        {showRetryCheckbox && (
          <>
            <input
              name="confirm"
              type="checkbox"
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
            <label htmlFor="confirm" style={{ paddingLeft: "6px" }}>
              {t("meterReadingConfirmHighValue")}
            </label>
          </>
        )}
        <Formik
          validationSchema={schema}
          initialValues={{
            meterReading: "",
          }}
          onSubmit={handleSubmit}
        >
          <Form className={styles.form}>
            <FormInputField name="meterReading" type="number" required />
            <Button type="submit" className={styles.btn}>
              {t("leaveMeterReading")}
            </Button>
          </Form>
        </Formik>
      </div>
      <Link to="/forbrukningshistorik" className={styles.link}>
        {t("seeConsumptionHistory")}{" "}
        <RoundIcon name="chevron-left" className={styles.roundIcon} />
      </Link>
    </div>
  );
}

import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import useMustacheData from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/useMustacheData";
import PropTypes from "prop-types";

import useNunjucks from "../../../hooks/useNunjucks";

HTML.propTypes = {
  children: PropTypes.string,
};

export default function HTML({ children: input, ...restProps }) {
  let data = useMustacheData();
  const { nunjucks } = useNunjucks();
  let htmlString = nunjucks
    ? nunjucks.renderString(String(input || ""), data)
    : input;
  let { processContent } = useHTMLProcessor();
  return processContent(htmlString, restProps);
}

/* global GATSBY_WHITESPACE_SMORGASBORD_PLUGIN_SVAAB_THEME_PATH */
import { ThemeProvider } from "@emotion/react";
import mergeThemes from "@whitespace/gatsby-theme-smorgasbord/src/mergeThemes";
import React from "react";

import SvaabApiProvider from "./SvaabApiProvider";
import SvaabCustomerProvider from "./SvaabCustomerProvider";

const {
  useThemeDefinition,
} = require(GATSBY_WHITESPACE_SMORGASBORD_PLUGIN_SVAAB_THEME_PATH);

export default function RootElementWrapper({ children }) {
  let theme = useThemeDefinition();
  return (
    <ThemeProvider theme={(parentTheme) => mergeThemes(parentTheme, theme)}>
      <SvaabApiProvider>
        <SvaabCustomerProvider>{children}</SvaabCustomerProvider>
      </SvaabApiProvider>
    </ThemeProvider>
  );
}

import { useEffect, useState } from "react";

export default function useTicker(ms) {
  const [tick, setTick] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => setTick(tick + 1), ms);
    return () => clearInterval(interval);
  }, [ms]);
  return tick;
}

// extracted by mini-css-extract-plugin
export var button = "Invoice-module--button--t7rvO";
export var component = "Invoice-module--component--fqLc4";
export var content = "Invoice-module--content--gqaT7";
export var label = "Invoice-module--label--3Vd3h";
export var link = "Invoice-module--link--VQuqt";
export var list = "Invoice-module--list--VeDH6";
export var status = "Invoice-module--status--8oUry";
export var status0 = "Invoice-module--status0--w9psl";
export var status1 = "Invoice-module--status1--IpEtU";
export var status2 = "Invoice-module--status2--If-km";
export var title = "Invoice-module--title--ud0fB";
export var value = "Invoice-module--value--1EGXN";
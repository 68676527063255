import { withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Contract from "./Contract";
import * as defaultStyles from "./Contracts.module.css";

Contracts.propTypes = { styles: PropTypes.objectOf(PropTypes.string) };

export default withComponentDefaults(Contracts, "contracts");

function Contracts({ styles = defaultStyles, ...restProps }) {
  const { t } = useTranslation();

  const data = [
    {
      address: "Borgmästargatan 16",
      contractId: "37428",
      id: "13-99-2-01",
      type: "Förrådsrum",
      period: "2021-06-01 - Tilllsvidare",
      noticePeriod: "3 månader",
      rent: "640,00 kr/månaden",
      area: "17 kvm",
    },
    {
      address: "Borgmästargatan 16",
      contractId: "15230",
      id: "29-17-9-79",
      type: "Lägenhet",
      period: "2021-06-01 - Tilllsvidare",
      noticePeriod: "3 månader",
      rent: "4 640,00 kr/månaden",
      area: "40 kvm",
    },
  ];

  return (
    <div className={clsx(styles.component)} {...restProps}>
      <p className={clsx(styles.description)}>
        {t("contractsPageDescription")}
      </p>
      <div className={clsx(styles.contracts)}>
        {data.map((data, index) => (
          <Contract item={data} key={index} />
        ))}
      </div>
    </div>
  );
}

/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useTicker } from "@whitespace/gatsby-theme-smorgasbord/src/hooks";
import { MustacheDataProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useEffect, useState, useContext } from "react";

import edpBuildingContext from "./edpBuildingContext";
import edpServiceContext from "./edpServiceContext";
import useEdpApi from "./useEdpApi";

export default function EdpServiceProvider({
  pollInterval = 20 * 60 * 1000, // 20 minutes
  children,
}) {
  const { edpApiUrl } = useEdpApi();
  const { Provider } = edpServiceContext;

  const { selectedEdpBuilding } = useContext(edpBuildingContext);

  const [pending, setPending] = useState(true);
  const [error, setError] = useState(null);
  const [edpServices, setEdpServices] = useState([]);
  const [selectedEdpServiceId, setSelectedEdpServiceId] = useState();

  const selectedEdpService =
    edpServices.find((edpService) => edpService.id === selectedEdpServiceId) ||
    edpServices[0];

  const selectEdpService = (edpServiceId) => {
    setSelectedEdpServiceId(edpServiceId);
  };

  const tick = useTicker(pollInterval);

  useEffect(async () => {
    if (selectedEdpBuilding?.id) {
      setPending(true);
      try {
        const response = await fetch(
          `${edpApiUrl}/buildings/${selectedEdpBuilding.id}/services`,
        );
        if (response.ok) {
          const { data } = await response.json();
          let edpServices = (data || []).map(
            ({ latestReadingValue, latestReadingDate, ...edpService }) => ({
              ...edpService,
              userIsAdmin: edpService.adminSsn === selectedEdpBuilding.id,
              latestReadingValue:
                latestReadingValue == null ? null : Number(latestReadingValue),
              latestReadingDate:
                latestReadingDate == null
                  ? null
                  : new Date(latestReadingDate).toLocaleDateString("sv", {
                      date: "long",
                    }),
            }),
          );
          setEdpServices(edpServices);
        } else {
          // console.log(response);
          throw new Error(response.statusText);
        }
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    } else {
      setEdpServices([]);
    }
    setPending(false);
  }, [selectedEdpBuilding?.id, tick]);

  let hasEdpServices = !!edpServices?.length;
  let isEdpService = hasEdpServices; // Alias for hasEdpServices
  let edpService = selectedEdpService; // Alias for selectedEdpService
  let edpServiceProviderErrored = !!error;

  return (
    <Provider
      value={{
        edpServices,
        error,
        pending,
        selectEdpService,
        selectedEdpService,
      }}
    >
      {!!process.env.GATSBY_DEBUG_CONTEXT && (
        <details>
          <summary>
            <code>EdpServiceProvider</code>
          </summary>
          <pre>
            <code>
              {JSON.stringify(
                {
                  edpServices,
                  edpService,
                  error,
                  pending,
                  selectEdpService,
                  selectedEdpService,
                },
                null,
                2,
              )}
            </code>
          </pre>
        </details>
      )}
      <MustacheDataProvider
        data={{
          isEdpService,
          hasEdpServices,
          edpServices,
          edpService,
          selectedEdpService,
          edpServiceProviderErrored,
        }}
      >
        {children}
      </MustacheDataProvider>
    </Provider>
  );
}

import { upperFirst, camelCase } from "lodash-es";
import PropTypes from "prop-types";
import React from "react";

import * as availableComponents from "./smorgasbord-modules";

SmorgasbordModule.propTypes = {
  module: PropTypes.shape({
    modSmorgasbordOptions: PropTypes.shape({
      panel: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
};
function getComponent(selectedComponent) {
  let componentName =
    selectedComponent &&
    `Smorgasbord${upperFirst(camelCase(selectedComponent))}Module`;
  return (
    // eslint-disable-next-line import/namespace
    componentName && availableComponents[componentName]
  );
}

export default function SmorgasbordModule({ module = {}, ...restProps }) {
  const selectedComponent = module?.modSmorgasbordOptions?.panel;
  const description = module?.modSmorgasbordOptions?.description || null;

  const Component = getComponent(selectedComponent);
  if (!Component) {
    // Don’t render this component in production
    if (process.env.NODE_ENV === "production") {
      return null;
    }

    return (
      <details>
        <summary>
          <code>
            {selectedComponent
              ? `Unimplemented "${selectedComponent}" component for smorgasbord module`
              : `Missing selected component for smorgasbord module`}
          </code>
        </summary>
        <pre>
          <code>{JSON.stringify(module, null, 2)}</code>
        </pre>
      </details>
    );
  }

  return <Component {...restProps} description={description} />;
}

import nunjucks from "nunjucks";
import React from "react";

import RootElementWrapper from "./src/components/RootElementWrapper";
import nunjucksContext from "./src/contexts/nunjucksContext";

function NunjucksProvider({ children }) {
  return (
    <nunjucksContext.Provider value={{ nunjucks }}>
      {children}
    </nunjucksContext.Provider>
  );
}

export const wrapRootElement = ({ element, ...restParams }, pluginOptions) => {
  return (
    <RootElementWrapper {...restParams} pluginOptions={pluginOptions}>
      <NunjucksProvider>{element}</NunjucksProvider>
    </RootElementWrapper>
  );
};

import { DashboardMenu } from "@whitespace/components";
import {
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import userContext from "@whitespace/gatsby-theme-smorgasbord/src/contexts/userContext";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { base64ToArrayBuffer, getBlobFileURL } from "../utils/byteToPdf.js";

import * as defaultStyles from "./WastePickupDownload.module.css";

WastePickupDownload.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function WastePickupDownload({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return null;
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState([]);
  const { get, error, loading } = useEdp();
  const { user } = useContext(userContext);

  const getMenuItems = useCallback(async () => {
    if (!user) return null;

    const menuPromises = user.edpCustomer?.buildings?.map(
      async ({ id, address }) => {
        const { data } = await get(`/buildings/${id}/waste-pickup`);
        const arrayBuffer = base64ToArrayBuffer(data);
        const url = getBlobFileURL(arrayBuffer);

        return {
          title: t(`downloadWastePickupSchedule`),
          url,
          download: `${address}-${id}.pdf`,
          icon: { name: "trash" },
          description: t(`downloadWastePickupSchedulePDF`),
        };
      },
    );

    if (!menuPromises) return null;

    const menuItems = await Promise.all(menuPromises);
    setMenuItems(menuItems);
  }, [JSON.stringify(user), user?.edpCustomer.buildings]);

  useEffect(() => {
    getMenuItems();
  }, [getMenuItems]);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {loading ? (
        <Loading />
      ) : menuItems.length > 0 ? (
        <DashboardMenu items={menuItems} />
      ) : error?.message ? (
        <ErrorMessage error={error.message} severity="warning" />
      ) : (
        <ErrorMessage error={t(`noScheduleFound`)} severity="warning" />
      )}
    </div>
  );
}

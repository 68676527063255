/** @jsx jsx */

import { jsx } from "@emotion/react";
import { ErrorMessage } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import { camelCase } from "lodash-es";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

EDPAuthError.propTypes = {
  error: PropTypes.string,
  onRetryClick: PropTypes.func,
};

export default function EDPAuthError({ error, ...restProps }) {
  const { t } = useTranslation();

  return (
    <ErrorMessage
      error={
        t([
          `edpAuth.error.${camelCase(error || "unknown")}`,
          `edpAuth.error.unknown`,
        ]) + (process.env.GATSBY_DEBUG_LOGIN && error ? ` (${error})` : "")
      }
      severity="error"
      {...restProps}
    />
  );
}

import PropTypes from "prop-types";
import React, { useContext } from "react";

import userContext from "../contexts/userContext";

import LoginDialog from "./LoginDialog";

AccessWall.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.elementType,
};

function DefaultFallbackComponent() {
  return <LoginDialog />;
}

export default function AccessWall({
  children,
  fallback: FallbackComponent = DefaultFallbackComponent,
}) {
  const { user } = useContext(userContext);
  const requireLogin = true;

  if (requireLogin && !user) {
    return <FallbackComponent />;
  }
  return children;
}

import * as defaultStyles from "@municipio/gatsby-theme-basic/src/components/ModularityArea.module.css";
import ModuleController from "@municipio/gatsby-theme-basic/src/components/ModuleController";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import { parseColumnWidth } from "@municipio/gatsby-theme-basic/src/utils";
import { MaybeFragment, PageGridGroup } from "@whitespace/components";
import { useMustacheData } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import useNunjucks from "../../../hooks/useNunjucks";

ModularityArea.propTypes = {
  area: PropTypes.any,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function ModularityArea({
  area = {},
  className,
  styles = defaultStyles,
  ...restProps
}) {
  const mustacheData = useMustacheData();
  const { nunjucks } = useNunjucks();
  const { modules } = area;
  if (!modules?.length) {
    return null;
  }
  // TODO: Wrap in PageGridGroup components based on columnWidth
  return (
    <modularityAreaContext.Provider value={area}>
      {!!modules && (
        <MaybeFragment
          as="div"
          className={clsx(styles.component, className)}
          {...restProps}
        >
          {modules.map(({ hidden, module, columnWidth, ...rest }, index) => {
            if (hidden || !module) {
              return null;
            }

            let visibility = module?.modSmorgasbord?.visibility;
            if (visibility) {
              let result;
              try {
                // Backwards compatibility with Mustache renderer
                visibility = visibility
                  .replace(/\{\{/g, "")
                  .replace(/\}\}/g, "");
                result = JSON.parse(
                  nunjucks.renderString(
                    `{{ "true" if ${visibility} else "false" }}`,
                    mustacheData,
                  ),
                );
              } catch {
                // Do nothing
              }
              if (!result) {
                return null;
              }
            }

            const MaybePageGridGroup =
              parseColumnWidth(columnWidth) || area.defaultModuleColSpan
                ? PageGridGroup
                : React.Fragment;
            return (
              <modularityModuleContext.Provider
                value={{ hidden, module, columnWidth, ...rest }}
                key={index}
              >
                <MaybePageGridGroup>
                  <ModuleController module={module} />
                </MaybePageGridGroup>
              </modularityModuleContext.Provider>
            );
          })}
        </MaybeFragment>
      )}
    </modularityAreaContext.Provider>
  );
}

import { Button, withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./HeaderTabs.module.css";

HeaderTabs.propTypes = {
  className: PropTypes.string,
  currentTab: PropTypes.string,
  handleSetCurrentTab: PropTypes.func,
  items: PropTypes.objectOf(PropTypes.shape({ label: PropTypes.node })),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(HeaderTabs, "headerTabs");

function HeaderTabs({
  className,
  styles = defaultStyles,
  items,
  currentTab,
  handleSetCurrentTab,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <ul className={clsx(styles.list, className)}>
        {Object.keys(items).map((key, index) => {
          const { label } = items[key];
          return (
            <li key={index}>
              <Button
                as="button"
                className={clsx(
                  styles.button,
                  currentTab == key && styles.isActive,
                )}
                onClick={() => {
                  handleSetCurrentTab(key);
                }}
              >
                {label}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

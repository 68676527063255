export default {
  color: {
    danger: {
      default: "#fdeded",
      foreground: "#5f2120",
    },
    warning: {
      default: "#fff4e5",
      foreground: "#663c00",
    },
    success: {
      default: "#e5f6fd",
      foreground: "#014361",
    },
    info: {
      default: "#e5f6fd",
      foreground: "#014361",
    },
  },
  noticeModule: {
    padding: "1.5rem",
    content: {
      fontSize: "1rem",
    },
  },
};

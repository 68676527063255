import { useTheme } from "@emotion/react";

/**
 * Returns the props to use for a component, merging the default props from the theme.
 * @param {Object} props The props that were passed to the component
 * @param {string} name The name of the component
 * @returns {Object} The props to use for the component
 */
export default function useThemeProps({ props, name }) {
  const theme = useTheme();
  const { defaultProps = {} } = theme.components?.[name] || {};
  return { ...defaultProps, ...props };
}

// extracted by mini-css-extract-plugin
export var backBtn = "MeterChange-module--backBtn--PwQtb";
export var component = "MeterChange-module--component--MF6u6";
export var info = "MeterChange-module--info--vuzLI";
export var link = "MeterChange-module--link--TH9Od";
export var loader = "MeterChange-module--loader--nsWl+";
export var roundIcon = "MeterChange-module--roundIcon--Ayjag";
export var submitBtn = "MeterChange-module--submitBtn--IgfFQ";
export var timeSlotsFooter = "MeterChange-module--timeSlotsFooter--vOC0+";
export var timeSlotsHeader = "MeterChange-module--timeSlotsHeader--DtrHq";
export var timeSlotsMain = "MeterChange-module--timeSlotsMain--MdHRY";
export var timeSlotsWrapper = "MeterChange-module--timeSlotsWrapper--0yN6n";
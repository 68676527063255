import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import WastePickupDownload from "../WastePickupDownload";

SmorgasbordWastePickupDownloadModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordWastePickupDownloadModule({
  styles = {},
  className,
  title,
  ...restProps
}) {
  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <WastePickupDownload />
    </ModuleWrapper>
  );
}

import { Link } from "@whitespace/components";
import Logo from "@whitespace/gatsby-theme-smorgasbord/src/components/Logo";
import userContext from "@whitespace/gatsby-theme-smorgasbord/src/contexts/userContext";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Header.module.css";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { logout } = useContext(userContext);
  const { t } = useTranslation();

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.header)}>
        <HeaderLogo align="center" components={{ Logo }} linkTo="/" />
        {
          <div className={styles.menus}>
            <Link onClick={() => logout()} className={clsx(styles.signout)}>
              {t("signOut")}
            </Link>
          </div>
        }
      </div>
    </header>
  );
}

import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import HTML from "@whitespace/gatsby-theme-wordpress-basic/src/components/HTML";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import MeterReading from "../MeterReading";

SmorgasbordMeterReadingModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordMeterReadingModule({
  styles = {},
  className,
  title,
  description,
  ...restProps
}) {
  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <MeterReading description={<HTML>{description}</HTML>} />
    </ModuleWrapper>
  );
}

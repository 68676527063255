import { mergeWith, isArray } from "lodash/fp";

const mergeAndConcat = mergeWith((objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
});

export default function mergeThemes(...args) {
  return mergeAndConcat(...args);
}

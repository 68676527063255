import PropTypes from "prop-types";
import React from "react";
import { Provider } from "use-http";

import UserProvider from "./UserProvider";

RootElementWrapper.propTypes = {
  children: PropTypes.node,
  pluginOptions: PropTypes.shape({
    apiURL: PropTypes.string,
    loginPath: PropTypes.string,
    logoutPath: PropTypes.string,
  }),
};

export default function RootElementWrapper({
  children,
  pluginOptions: { apiURL = "/api", loginPath, logoutPath } = {},
}) {
  const options = {
    interceptors: {
      // every time we make an http request, before getting the response back, this will run
      response: async ({ response }) => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response;
      },
    },
  };
  return (
    <Provider url={apiURL} options={options}>
      <UserProvider
        userInfoURL={`${apiURL}/user`}
        loginURL={loginPath && `${apiURL}/${loginPath}`}
        logoutURL={logoutPath && `${apiURL}/${logoutPath}`}
      >
        {children}
      </UserProvider>
    </Provider>
  );
}

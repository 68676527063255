import { Section } from "@jfrk/react-heading-levels";
import {
  Link,
  Icon,
  RoundIcon,
  withComponentDefaults,
} from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import SectionHeader from "./SectionHeader";
import * as defaultStyles from "./Services.module.css";

export default withComponentDefaults(Services, "services");

Services.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};

function Services({
  className,
  styles = defaultStyles,
  title,
  items,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Section>
        <SectionHeader title={title} />
      </Section>
      <nav aria-label={t("mainMenuLabel")}>
        <ul className={clsx(styles.list)}>
          {items.map((item, index) => {
            return (
              <li className={clsx(styles.item)} key={index}>
                <Link to={item.url} className={clsx(styles.link)}>
                  <RoundIcon
                    className={clsx(styles.roundIcon)}
                    name={item.icon}
                  />
                  {t(item.label)}
                  <Icon
                    name="chevron-right"
                    className={clsx(styles.linkIcon)}
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Loading.module.css";

Loading.propTypes = {
  className: PropTypes.string,
  showText: PropTypes.bool,
  styles: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.any,
  fullscreen: PropTypes.bool,
};

export default function Loading({
  className,
  showText = true,
  styles = defaultStyles,
  text = true,
  fullscreen = false,
  ...restProps
}) {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        styles.component,
        fullscreen && styles.fullscreen,
        className,
      )}
      {...restProps}
    >
      <div className={clsx(styles.spinner, className)} />
      <div className={clsx(styles.text, showText || visuallyHidden, className)}>
        {text === true ? `${t("loadingText")}…` : text}
      </div>
    </div>
  );
}

import { H } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as defaultStyles from "./Tabs.module.css";

Tabs.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  currentTabIndex: PropTypes.number,
  handleSetCurrentTabIndex: PropTypes.func,
  handleSetFocus: PropTypes.func,
};

export default withComponentDefaults(Tabs, "tabs");

function Tabs({
  className,
  styles = defaultStyles,
  items,
  currentTabIndex,
  handleSetCurrentTabIndex,
  handleSetFocus,
  title,
  hideTitle = true,
  ...restProps
}) {
  const buttonRefs = useRef([]);

  const handleKeyDown = (index, event) => {
    let dir =
      event.which === 37
        ? index - 1
        : event.which === 39
        ? index + 1
        : event.which === 40
        ? "down"
        : null;

    if (dir !== null) {
      event.preventDefault();
      if (dir === "down") {
        handleSetFocus(index);
      } else if (items[dir]) {
        buttonRefs.current[dir].focus();
        handleSetCurrentTabIndex(dir);
      }
    }
  };

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.componentInner)}>
        {title && <H className={hideTitle && visuallyHidden}>{title}</H>}
        <ul className={clsx(styles.list, className)} role="tablist">
          {items.map((item, index) => {
            const { label } = item;
            return (
              <li key={index} className={styles.item} role="presentation">
                <button
                  type="button"
                  role="tab"
                  aria-controls={`panel-${index}`}
                  id={`tab-${index}`}
                  ref={(btn) => (buttonRefs.current[index] = btn)}
                  className={clsx(styles.button)}
                  aria-selected={currentTabIndex === index ? "true" : null}
                  onClick={() => {
                    handleSetCurrentTabIndex(index);
                  }}
                  onKeyDown={(e) => {
                    handleKeyDown(index, e);
                  }}
                >
                  {label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

/* global GATSBY_WHITESPACE_SMORGASBORD_PLUGIN_EDP_THEME_PATH */
import { ThemeProvider } from "@emotion/react";
import mergeThemes from "@whitespace/gatsby-theme-smorgasbord/src/mergeThemes";
import React from "react";

import EdpApiProvider from "./EdpApiProvider";
import EdpBuildingProvider from "./EdpBuildingProvider";
import EdpCustomerProvider from "./EdpCustomerProvider";
import EdpServiceProvider from "./EdpServiceProvider";

const {
  useThemeDefinition,
} = require(GATSBY_WHITESPACE_SMORGASBORD_PLUGIN_EDP_THEME_PATH);

export default function RootElementWrapper({ children }) {
  let theme = useThemeDefinition();
  return (
    <ThemeProvider theme={(parentTheme) => mergeThemes(parentTheme, theme)}>
      <EdpApiProvider>
        <EdpCustomerProvider>
          <EdpBuildingProvider>
            <EdpServiceProvider>{children}</EdpServiceProvider>
          </EdpBuildingProvider>
        </EdpCustomerProvider>
      </EdpApiProvider>
    </ThemeProvider>
  );
}

/** @jsx jsx */
import { jsx } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import {
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useContext } from "react";

import edpBuildingContext from "../../edpBuildingContext.js";
import edpServiceContext from "../../edpServiceContext.js";

SmorgasbordEdpServiceSelectorModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordEdpServiceSelectorModule({
  styles = {},
  className,
  title,
  ...restProps
}) {
  const {
    edpBuildings,
    selectedEdpBuilding,
    selectEdpBuilding,
    pending: loadingBuildings,
    error: errorBuildings,
  } = useContext(edpBuildingContext);
  const { edpServices, selectedEdpService, selectEdpService } =
    useContext(edpServiceContext);

  if (loadingBuildings || edpBuildings.length === 0) {
    return null;
  }

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      {loadingBuildings ? (
        <Loading />
      ) : errorBuildings ? (
        <ErrorMessage error={errorBuildings.message} />
      ) : (
        <span>
          <label htmlFor="building-selector" className={styles.label}>
            För{" "}
          </label>
          {edpBuildings.length === 1 ? (
            selectedEdpBuilding.address
          ) : (
            <select
              placeholder="Byt anläggning"
              value={selectedEdpBuilding?.id || ""}
              onChange={(event) => {
                selectEdpBuilding(event.target.value);
              }}
            >
              {edpBuildings.map(({ id, address }) => (
                <option key={id} value={id}>
                  {address}
                </option>
              ))}
            </select>
          )}
          {". "}
          {edpServices.length === 1 ? (
            selectedEdpService.description
          ) : (
            <select
              placeholder="Byt tjänst"
              value={selectedEdpService?.id || ""}
              onChange={(event) => {
                selectEdpService(event.target.value);
              }}
            >
              {edpServices.map(({ id, description }) => (
                <option key={id} value={id}>
                  {description}
                </option>
              ))}
            </select>
          )}
        </span>
      )}
    </ModuleWrapper>
  );
}

import PropTypes from "prop-types";
import React, { useContext, createContext, useReducer } from "react";

const DialogContext = createContext({});

const initialState = ({
  showStartScreen,
  showCustomerNumberLoginForm,
  showEmailConfirmationForm,
  showAccountChoosingForm,
}) => ({
  showStartScreen,
  showCustomerNumberLoginForm,
  showEmailConfirmationForm,
  showAccountChoosingForm,
});

const reducer = (state, { action }) => {
  switch (action) {
    case "SHOW_START_SCREEN":
      return {
        ...state,
        showStartScreen: true,
        showCustomerNumberLoginForm: false,
        showAccountChoosingForm: false,
      };
    case "SHOW_CUSTOMER_NUMBER_LOGIN":
      return {
        ...state,
        showCustomerNumberLoginForm: true,
        showStartScreen: false,
        showAccountChoosingForm: false,
      };
    case "SHOW_EMAIL_CONFIRMATION_FORM":
      return {
        ...state,
        showEmailConfirmationForm: true,
        showCustomerNumberLoginForm: false,
        showStartScreen: false,
        showAccountChoosingForm: false,
      };
    case "SHOW_ACCOUNT_CHOOSING_FORM":
      return {
        ...state,
        showAccountChoosingForm: true,
        showEmailConfirmationForm: false,
        showCustomerNumberLoginForm: false,
        showStartScreen: false,
      };
    default:
      return state;
  }
};

export const DialogProvider = ({
  showStartScreen = true,
  showCustomerNumberLoginForm = false,
  showEmailConfirmationForm = false,
  showAccountChoosingForm = false,
  children,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState({
      showStartScreen,
      showCustomerNumberLoginForm,
      showEmailConfirmationForm,
      showAccountChoosingForm,
    }),
  );
  return (
    <DialogContext.Provider value={{ state, dispatch }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => useContext(DialogContext);

DialogProvider.propTypes = {
  children: PropTypes.node,
  showStartScreen: PropTypes.bool,
  showCustomerNumberLoginForm: PropTypes.bool,
  showEmailConfirmationForm: PropTypes.bool,
  showAccountChoosingForm: PropTypes.bool,
};

import { Button, FormInputField } from "@whitespace/components";
import { Loading } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import { useUserContext } from "@whitespace/gatsby-theme-smorgasbord/src/hooks";
import { useAsync } from "@whitespace/gatsby-theme-smorgasbord/src/utils";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import EDPAuthError from "./EDPAuthError";
import * as defaultStyles from "./EmailConfirmationForm.module.css";

EmailConfirmationForm.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

const schema = yup.object().shape({
  code: yup.string().length(6).required(),
});

export default function EmailConfirmationForm({ styles = defaultStyles }) {
  let continueAuthentication = useAsync(async (body) => {
    let response = await window.fetch("/api/edp/login/verify-code", {
      method: "post",
      cache: "no-cache",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (!response.ok) {
      let error = new Error(data.name);
      Object.assign(error, data);
      throw error;
    }
    return data;
  });

  let beginAuthentication = useAsync(async (body) => {
    let response = await window.fetch("/api/edp/login", {
      method: "post",
      cache: "no-cache",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (!response.ok) {
      let error = new Error(data.name);
      Object.assign(error, data);
      throw error;
    }
    return data;
  });

  const { t } = useTranslation();
  const { pollUser } = useUserContext();

  const handleSubmit = async ({ code }) => {
    await continueAuthentication.execute({ code });
    await pollUser();
  };

  const handleSendNewEmail = async (e) => {
    await beginAuthentication.execute({});
    e.preventDefault();
  };

  if (continueAuthentication.success) {
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>;
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        code: "",
      }}
      className={styles.form}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <h1 className={clsx(styles.headline)}>{t("confirmViaEmail")}</h1>
          <p className={clsx(styles.description)}>
            {t("confirmViaEmailDescription")}
          </p>

          {continueAuthentication.pending && (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          )}

          {continueAuthentication.error && (
            <div className={styles.errorWrapper}>
              <EDPAuthError error={continueAuthentication.error.code} />
            </div>
          )}

          <div className={styles.inputWrapper}>
            <FormInputField
              type="text"
              inputMode="numeric"
              name="code"
              autoComplete="off"
              onFocus={(event) => {
                event.target.select();
              }}
              disabled={isSubmitting}
            />
          </div>

          <Button
            type="submit"
            className={styles.submitBtn}
            disabled={isSubmitting || !isValid}
          >
            {t("continue")}
          </Button>

          <Button
            type="button"
            className={styles.sendEmailBtn}
            onClick={handleSendNewEmail}
            disabled={isSubmitting}
          >
            {t("resendCode")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

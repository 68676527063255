import AccountChoosingForm from "@whitespace/gatsby-plugin-smorgasbord-edp/src/components/AccountChoosingForm";
import edpCustomerContext from "@whitespace/gatsby-plugin-smorgasbord-edp/src/edpCustomerContext";
import AccessWall from "@whitespace/gatsby-theme-smorgasbord/src/components/AccessWall";
import Dialog from "@whitespace/gatsby-theme-smorgasbord/src/Dialog";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/SiteLayout.module.css";
import { useMustacheData } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";
import { Helmet } from "react-helmet";

SiteLayout.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function SiteLayout({
  styles = defaultStyles,
  className,
  children,
}) {
  const { edpCustomers, selectedEdpCustomer } = useContext(edpCustomerContext);
  const mustacheData = useMustacheData();
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <link rel="icon" href="favicon.svg" />
        <meta
          name="theme-color"
          content="#3572A8"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#040A0E
        "
          media="(prefers-color-scheme: dark)"
        />
      </Helmet>
      <AccessWall>
        {!!process.env.GATSBY_DEBUG_CONTEXT && (
          <details>
            <summary>
              <code>MustacheData</code>
            </summary>
            <pre>
              <code>{JSON.stringify(mustacheData, null, 2)}</code>
            </pre>
          </details>
        )}

        {edpCustomers?.length > 1 && !selectedEdpCustomer ? (
          <Dialog>
            <AccountChoosingForm />
          </Dialog>
        ) : (
          <div className={clsx(styles.component, className)}>
            <Header />
            <main className={styles.main}>{children}</main>
          </div>
        )}
      </AccessWall>
    </>
  );
}

export const SiteLayoutContext = createContext();

import { MustacheDataProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import userContext from "../contexts/userContext";

UserProvider.propTypes = {
  children: PropTypes.node,
  loginURL: PropTypes.string,
  logoutURL: PropTypes.string,
  requireLogin: PropTypes.bool,
  userInfoURL: PropTypes.string.isRequired,
  userSettingsURL: PropTypes.string,
};

export default function UserProvider({
  children,
  loginURL,
  logoutURL,
  requireLogin,
  userInfoURL,
  userSettingsURL,
}) {
  const { Provider } = userContext;
  const [user, setUser] = useState();
  const [pending, setPending] = useState(true);

  const pollUser = useCallback(async () => {
    try {
      const response = await window.fetch(userInfoURL, { cache: "no-cache" });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const { data: user } = await response.json();
      setUser(user);
    } catch (error) {
      //
    }
    setPending(false);
  }, [userInfoURL]);

  useEffect(() => {
    pollUser();
    // let id = setInterval(pollUser, 10 * 1000);
    // () => {
    //   clearInterval(id);
    // };
  }, [pollUser]);

  const login = useCallback(
    async (handler) => {
      if (handler) {
        // If a handler is provided it should return the logged in user
        let user = await handler();
        setUser(user);
      } else if (loginURL) {
        // If there’s no handler, but there’s a login URL, redirect
        let redirectURL = new URL(loginURL, window.location.origin);
        redirectURL.searchParams.set("returnTo", window.location.href);
        window.location = redirectURL.href;
      } else {
        // If there’s no handler nor a login URL simply poll for the user and
        // hope that they’ve been logged in already.
        await pollUser();
      }
    },
    [loginURL],
  );

  const logout = useCallback(async () => {
    // let redirectURL = new URL(logoutURL, window.location.origin);
    await fetch(logoutURL, { cache: "no-cache" });
    setUser(null);
  }, [logoutURL]);

  const userSettings = useMemo(() => user?.settings, [JSON.stringify(user)]);

  const setUserSettings = useCallback(
    async (newValue) => {
      if (!userSettingsURL || !user) {
        return;
      }
      if (typeof newValue === "function") {
        newValue = newValue(userSettings);
      }
      try {
        setUser({ ...user, settings: newValue });
        let response = await window.fetch(userSettingsURL, {
          method: "put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newValue),
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
      } catch (error) {
        //
      }
    },
    [userSettingsURL, JSON.stringify(user), JSON.stringify(userSettings)],
  );

  return (
    <Provider
      value={{
        user,
        setUser,
        pending,
        logout,
        login,
        userInfoURL,
        loginURL,
        logoutURL,
        requireLogin,
        userSettings,
        setUserSettings,
        pollUser,
      }}
    >
      <MustacheDataProvider data={{ user }}>{children}</MustacheDataProvider>
    </Provider>
  );
}

import {
  Alert,
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import useInvoicesSelectStyles from "@whitespace/gatsby-theme-smorgasbord/src/hooks/useInvoicesSelectStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import edpBuildingContext from "../edpBuildingContext.js";
import edpCustomerContext from "../edpCustomerContext.js";
import edpServiceContext from "../edpServiceContext.js";
import useEdpApi from "../useEdpApi.js";

import * as defaultStyles from "./ServiceUsage.module.css";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

ServiceUsage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ServiceUsage({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();

  const { fetch } = useEdpApi();
  const { selectedEdpCustomer } = useContext(edpCustomerContext);
  const { selectedEdpBuilding } = useContext(edpBuildingContext);
  const { selectedEdpService } = useContext(edpServiceContext);

  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  const [usage, setUsage] = useState([]);
  const [expectedYearlyUsage, setExpectedYearlyUsage] = useState();
  const [selectedYear, setSelectedYear] = useState();

  const selectStyles = useInvoicesSelectStyles();

  const allYears = usage.map(
    ({ text: year }) =>
      (year = {
        value: year,
        label: year,
      }),
  );

  const currentView = usage.find(
    ({ text: year }) => year === selectedYear?.value,
  );

  const isCurrentYear = selectedYear?.value === allYears[0]?.value;
  const yearlyUsage = currentView?.values.double
    .reduce((acc, value) => {
      return acc + parseFloat(Object.values(value).join(""));
    }, 0)
    .toLocaleString();

  useEffect(async () => {
    if (
      selectedEdpService?.id &&
      selectedEdpCustomer?.id &&
      fetch &&
      !pending
    ) {
      setPending(true);
      try {
        const response = await fetch(
          `/customers/${selectedEdpCustomer.id}/buildings/${selectedEdpBuilding.id}/services/${selectedEdpService.id}/usage`,
        );
        const {
          data: { usage, preliminaryYearlyconsumption },
        } = await response.json();

        if (preliminaryYearlyconsumption) {
          setExpectedYearlyUsage(
            parseFloat(preliminaryYearlyconsumption, 10).toLocaleString(),
          );
        }

        if (usage.length) {
          setUsage(usage);
        }
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    } else {
      setUsage([]);
    }
    setPending(false);
  }, [selectedEdpService?.id, selectedEdpCustomer?.id, fetch]);

  useEffect(() => {
    setSelectedYear(allYears[0]);
  }, [JSON.stringify(allYears)]);

  if (pending) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error.message} />;
  }

  if (!usage.length) {
    return <Alert msg={t("noServiceUsage")} />;
  }

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.filter)}>
        <label htmlFor="year-selector" className={clsx(styles.label)}>
          {t("selectUsageYearLabel")}
        </label>
        <Select
          inputId="year-selector"
          className={styles.select}
          styles={selectStyles}
          value={selectedYear}
          isSearchable={false}
          onChange={(selectedYear) => {
            setSelectedYear(selectedYear);
          }}
          options={allYears}
        />
      </div>

      {currentView && (
        <table className={clsx(styles.table)} {...restProps}>
          <thead className={clsx(styles.head)}>
            <tr className={clsx(styles.headTr)}>
              <th className={clsx(styles.th)} scope="col">
                {t("month")}
              </th>
              <th className={clsx(styles.th)} scope="col">
                {t("serviceUsage")}
              </th>
            </tr>
          </thead>
          <tbody className={clsx(styles.body)}>
            {currentView.values.double.map((value, index) => {
              let serviceUsage = Object.values(value).join("");
              if (serviceUsage === "0") return null;
              serviceUsage = parseFloat(serviceUsage, 10).toLocaleString();
              return (
                <tr key={index}>
                  <td className={clsx(styles.td)}>{t(months[index])}</td>
                  <td className={clsx(styles.td)}>{serviceUsage} m³</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {isCurrentYear && (
        <div className={clsx(styles.usageStats)}>
          <dl>
            <dt>{t("yearlyUsage")}</dt>
            <dd>{yearlyUsage} m³</dd>
            <dt>{t("expectedYearlyUsage")}</dt>
            <dd>{expectedYearlyUsage} m³</dd>
          </dl>
        </div>
      )}
    </div>
  );
}

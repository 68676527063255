import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { useDialogContext } from "../contexts/dialogContext";
import municipalityContext from "../municipalityContext";

import * as defaultStyles from "./MunicipalitySelector.module.css";

MunicipalitySelector.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  showLabel: PropTypes.bool,
};

const municipalitiesStyles = {
  valueContainer: (provided, state) => {
    return {
      ...provided,
      minHeight: "3.25rem",
    };
  },
};

export default function MunicipalitySelector({
  styles = defaultStyles,
  className,
  showLabel = true,
  ...restProps
}) {
  const { t } = useTranslation();
  let {
    municipalities: options,
    selectMunicipality: setMunicipality,
    selectedMunicipality,
  } = useContext(municipalityContext);
  const { state } = useDialogContext();

  let municipality = selectedMunicipality?.id;

  if (state?.showCustomerNumberLoginForm) {
    // Filter out Åstorp since it does not belong to EDP
    options = options.filter((option) => option.value !== "astorp");
  }

  const value = options.find(({ value }) => municipality === value);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <label
        htmlFor="municipality-selector"
        className={clsx(styles.label, showLabel || visuallyHidden)}
      >
        {t("loginToMunicipality")}
      </label>
      <Select
        inputId="municipality-selector"
        className={styles.select}
        styles={municipalitiesStyles}
        value={value}
        isSearchable={false}
        options={options}
        onChange={({ value }) => setMunicipality(value)}
      />
    </div>
  );
}

import { withComponentDefaults } from "@whitespace/components";
import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import * as defaultStyles from "./Modal.module.css";

export default withComponentDefaults(Modal, "modal");

Modal.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.string,
  children: PropTypes.node,
  closeModal: PropTypes.func,
};

export function Modal({
  className,
  styles = defaultStyles,
  children,
  closeModal,
  ...restProps
}) {
  useEffect(() => {
    let originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);
  return (
    <div
      className={clsx(styles.component, className)}
      {...restProps}
      role="dialog"
      aria-modal="true"
    >
      <div className={styles.backdrop} onClick={closeModal} />
      <FocusTrap
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          onDeactivate: () => {
            // @TODO Find what's re-rerending <Modal/> and therefore triggering this callback
            // closeModal();
          },
        }}
      >
        <div className={styles.container}>
          <div className={styles.inner}>
            <button
              type="button"
              onClick={closeModal}
              className={clsx(styles.close, className)}
            >
              <span className={clsx(visuallyHidden)}>Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={clsx(styles.closeIcon, className)}
              >
                <path d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z" />
              </svg>
            </button>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </FocusTrap>
    </div>
  );
}

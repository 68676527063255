// extracted by mini-css-extract-plugin
export var component = "ServiceUsage-module--component--93AKF";
export var filter = "ServiceUsage-module--filter--VkWKM";
export var head = "ServiceUsage-module--head--sQZ0I";
export var label = "ServiceUsage-module--label--uBcpA";
export var select = "ServiceUsage-module--select--9g-rI";
export var table = "ServiceUsage-module--table--5xJG9";
export var td = "ServiceUsage-module--td--f98NE";
export var th = "ServiceUsage-module--th---gmlU";
export var title = "ServiceUsage-module--title--oTXHh";
export var usageStats = "ServiceUsage-module--usageStats--PW454";
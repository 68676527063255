export function getFilteredInvoices(condition, items) {
  let today = new Date();
  // get YYYY-MM part
  today = today.toISOString().slice(0, 7);

  return items.filter((invoice) => {
    let dueDate = new Date(invoice.dueDate);
    invoice.year = dueDate.getFullYear();
    // get YYYY-MM part
    dueDate = dueDate.toISOString().slice(0, 7);

    return condition === "<"
      ? dueDate < today
      : condition === "="
      ? dueDate === today
      : true;
  });
}

export function getInvoicesYear(items) {
  let years = items.reduce((arr, nextItem) => {
    const nextDate = new Date(nextItem.dueDate).getFullYear();

    if (!arr || (arr && !arr.includes(nextDate))) {
      arr.push(nextDate);
    }

    return arr;
  }, []);

  return years.map(
    (year) =>
      (year = {
        value: year,
        label: year,
      }),
  );
}

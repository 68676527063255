import { Button, FormRadioGroupField } from "@whitespace/components";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import edpCustomerContext from "../edpCustomerContext";

import * as defaultStyles from "./AccountChoosingForm.module.css";

AccountChoosingForm.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function AccountChoosingForm({
  className,
  styles = defaultStyles,
  ...restProps
}) {
  const { t } = useTranslation();
  const { selectEdpCustomer, edpCustomers } = useContext(edpCustomerContext);

  const handleSubmit = async (values) => {
    if (values?.customerId) {
      await selectEdpCustomer(values.customerId);
    }
  };

  const options = edpCustomers?.map((account) => account.customerId);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Formik
        initialValues={{
          customerId: options?.[0],
        }}
        validationSchema={yup.object().shape({
          customerId: yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        <Form className={clsx(styles.form)}>
          <FormRadioGroupField
            name="customerId"
            label={t("chooseAccountNumber")}
            options={options}
            description={t("chooseAccountCopy")}
          />
          <Button
            type="submit"
            className={clsx(styles.loginBtn, options && styles.btn)}
          >
            {t("Continue")}
          </Button>
        </Form>
      </Formik>
    </div>
  );
}

import { withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./Action.module.css";

export default withComponentDefaults(Action, "action");

Action.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.node,
  heading: PropTypes.string,
};

function Action({
  className,
  styles = defaultStyles,
  children,
  label,
  heading,
}) {
  return (
    <div className={clsx(styles.component, className)}>
      <div className={clsx(styles.componentInner, className)}>
        <span className={clsx(styles.label, className)}>{label}</span>
        <strong className={clsx(styles.heading, className)}>{heading}</strong>
        <div className={clsx(styles.actions, className)}>{children}</div>
      </div>
    </div>
  );
}

import { H } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useMustacheData } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

SmorgasbordDebugModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordDebugModule({
  styles = {},
  className,
  title,
  ...restProps
}) {
  let data = useMustacheData();
  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <pre>
        <code>{JSON.stringify(data, null, 2)}</code>
      </pre>
      <H>Example</H>
      <dl>
        <dt>
          <code>{`Hello, {{user.name}}`}</code>
        </dt>
        <dd>
          <HTML>{`Hello, {{user.name}}`}</HTML>
        </dd>
      </dl>
    </ModuleWrapper>
  );
}

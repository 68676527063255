/** @jsx jsx */
import { jsx } from "@emotion/react";
import {
  useTicker,
  useUserContext,
} from "@whitespace/gatsby-theme-smorgasbord/src/hooks";
import { MustacheDataProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useEffect, useState } from "react";

import edpCustomerContext from "./edpCustomerContext";
import useEdpApi from "./useEdpApi";

export default function EdpCustomerProvider({
  pollInterval = 20 * 60 * 1000, // 20 minutes
  children,
}) {
  const { edpApiUrl } = useEdpApi();
  const { Provider } = edpCustomerContext;

  const { user } = useUserContext();

  const [pending, setPending] = useState(true);
  const [error, setError] = useState(null);
  const [edpCustomers, setEdpCustomers] = useState([]);
  const [selectedEdpCustomerId, setSelectedEdpCustomerId] = useState();
  const [fetchTrigger, setFetchTrigger] = useState(false);

  const selectedEdpCustomer =
    edpCustomers.find(
      (edpCustomer) => edpCustomer.id === selectedEdpCustomerId,
    ) || (edpCustomers.length === 1 ? edpCustomers[0] : undefined);

  const selectEdpCustomer = (edpCustomerId) => {
    setSelectedEdpCustomerId(edpCustomerId);
  };

  const triggerFetch = () => setFetchTrigger(!fetchTrigger);

  const tick = useTicker(pollInterval);

  useEffect(async () => {
    if (user?.personalNumber && edpApiUrl) {
      setPending(true);
      try {
        const response = await fetch(`${edpApiUrl}/customers`);
        if (response.ok) {
          const { data } = await response.json();
          let edpCustomers = (data || []).map((edpCustomer) => ({
            ...edpCustomer,
            userIsAdmin: edpCustomer.adminSsn === user.personalNumber,
          }));
          setEdpCustomers(edpCustomers);
        } else {
          // console.log(response);
          throw new Error(response.statusText);
        }
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    } else {
      setEdpCustomers([]);
    }
    setPending(false);
  }, [user?.personalNumber, tick, edpApiUrl, fetchTrigger]);

  let hasEdpCustomers = !!edpCustomers?.length;
  let isEdpCustomer = hasEdpCustomers; // Alias for hasEdpCustomers
  let edpCustomer = selectedEdpCustomer; // Alias for selectedEdpCustomer
  let edpCustomerProviderErrored = !!error;
  const approvedEServices =
    selectedEdpCustomer?.approvedEservices?.eServiceType;
  const invoicesEnabled = approvedEServices?.includes("Invoices");
  const consumptionHistoryEnabled =
    approvedEServices?.includes("ConsumptionHistory");
  const serviceHistoryEnabled = approvedEServices?.includes("ServiceHistory");
  const edpMeterReadingEnabled = approvedEServices?.includes("MeterReading");

  return (
    <Provider
      value={{
        edpCustomers,
        error,
        pending,
        selectEdpCustomer,
        selectedEdpCustomer,
        triggerFetch,
      }}
    >
      <MustacheDataProvider
        data={{
          isEdpCustomer,
          hasEdpCustomers,
          edpCustomers,
          edpCustomer,
          selectedEdpCustomer,
          edpCustomerProviderErrored,
          invoicesEnabled,
          consumptionHistoryEnabled,
          serviceHistoryEnabled,
          edpMeterReadingEnabled,
        }}
      >
        {!!process.env.GATSBY_DEBUG_CONTEXT && (
          <details>
            <summary>
              <code>EdpCustomerProvider</code>
            </summary>
            <pre>
              <code>
                {JSON.stringify(
                  {
                    error,
                    pending,
                    edpCustomersCount: edpCustomers.length,
                    selectedEdpCustomer,
                    edpCustomers,
                  },
                  null,
                  2,
                )}
              </code>
            </pre>
          </details>
        )}
        {children}
      </MustacheDataProvider>
    </Provider>
  );
}

import { Button, FormInputField } from "@whitespace/components";
import { Loading } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import { useAsync } from "@whitespace/gatsby-theme-smorgasbord/src/utils";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import "yup-personnummer";

import { useDialogContext } from "../contexts/dialogContext";
import useStrings from "../hooks/useStrings";
import municipalityContext from "../municipalityContext";

import * as defaultStyles from "./CustomerNumberLoginForm.module.css";
import EDPAuthError from "./EDPAuthError";
import MunicipalitySelector from "./MunicipalitySelector";

CustomerNumberLoginForm.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function CustomerNumberLoginForm({ styles = defaultStyles }) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    personalNumber: yup
      .string()
      .required(t("You need to enter your personal number")),
    customerNumber: yup
      .string()
      .required(t("You need to enter your customer number")),
  });

  let beginAuthenticate = useAsync(async (body) => {
    let response = await window.fetch("/api/edp/login", {
      method: "post",
      cache: "no-cache",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (!response.ok) {
      let error = new Error(data.name);
      Object.assign(error, data);
      throw error;
    }
    return data;
  });

  const { selectedMunicipality } = useContext(municipalityContext);
  const { textLoginCustomerId } = useStrings();
  const { dispatch } = useDialogContext();

  const handleSubmit = async (values) =>
    await beginAuthenticate.execute({
      municipality: selectedMunicipality?.id,
      ...values,
    });

  useEffect(() => {
    if (!beginAuthenticate.pending && beginAuthenticate.value?.success) {
      dispatch({ action: "SHOW_EMAIL_CONFIRMATION_FORM" });
    }
  }, [JSON.stringify(beginAuthenticate)]);

  useEffect(() => {
    if (selectedMunicipality?.id === "astorp") {
      dispatch({ action: "SHOW_START_SCREEN" });
    }
  }, [selectedMunicipality?.id]);

  return (
    <>
      <h1 className={styles.headline}>{t("loginWithCustomerNumber")}</h1>
      {textLoginCustomerId && (
        <p className={styles.description}>{textLoginCustomerId}</p>
      )}

      {beginAuthenticate.pending && <Loading />}
      {beginAuthenticate.error && (
        <EDPAuthError error={beginAuthenticate.error.code} />
      )}

      <Formik
        validationSchema={schema}
        initialValues={{
          personalNumber:
            process.env.GATSBY_EDP_AUTH_DEFAULT_PERSONAL_NUMBER || "",
          customerNumber:
            process.env.GATSBY_EDP_AUTH_DEFAULT_CUSTOMER_NUMBER || "",
        }}
        onSubmit={handleSubmit}
        className={styles.form}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <div className={styles.formField}>
              <MunicipalitySelector />
            </div>

            <div className={styles.formField}>
              <label htmlFor="personalNumber">{t("ssnLabel")}</label>
              <FormInputField
                id="personalNumber"
                type="text"
                inputMode="numeric"
                name="personalNumber"
                description={t("Enter your personal number")}
                required
              />
            </div>
            <div className={styles.formField}>
              <label htmlFor="customerNumber">{t("customerIdLabel")}</label>
              <FormInputField
                id="customerNumber"
                type="text"
                inputMode="numeric"
                name="customerNumber"
                description={t("Enter your customer number")}
                required
              />
            </div>
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              type="submit"
              className={styles.loginBtn}
            >
              {t("logInLabel")}
            </Button>
            <Button
              type="button"
              className={styles.backBtn}
              onClick={() => dispatch({ action: "SHOW_START_SCREEN" })}
            >
              {t("backLabel")}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

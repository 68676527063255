import { useContext } from "react";

import municipalityContext from "./src/municipalityContext";

export const useThemeDefinition = () => {
  const { selectedMunicipality } = useContext(municipalityContext);
  return {
    components: {
      SmorgasbordEdpApiProvider: {
        defaultProps: {
          url: selectedMunicipality?.apiNamespace
            ? `/api/edp/${selectedMunicipality.apiNamespace}`
            : null,
        },
      },
    },
  };
};

import React from "react";

import DialogWrapper from "../../../components/DialogWrapper";
import { DialogProvider } from "../../../contexts/dialogContext";

export default function LoginDialog() {
  return (
    <DialogProvider>
      <DialogWrapper />
    </DialogProvider>
  );
}

import {
  Alert,
  ErrorMessage,
  Loading,
  Invoices as InvoicesComponent,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import edpCustomerContext from "../edpCustomerContext.js";
import useEdpApi from "../useEdpApi.js";
import transformInvoice from "../utils/transformInvoice.js";

import * as defaultStyles from "./Invoices.module.css";

Invoices.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Invoices({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();

  const { fetch, edpApiUrl } = useEdpApi();
  const { selectedEdpCustomer } = useContext(edpCustomerContext);

  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [invoices, setInvoices] = useState([]);

  useEffect(async () => {
    if (selectedEdpCustomer?.id && fetch && !pending) {
      setPending(true);
      try {
        const response = await fetch(
          `/customers/${selectedEdpCustomer.id}/invoices`,
        );
        const { data } = await response.json();
        setInvoices(data);
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    } else {
      setInvoices([]);
    }
    setPending(false);
  }, [fetch, selectedEdpCustomer?.id]);

  if (pending) {
    return (
      <div className={clsx(styles.component, className)} {...restProps}>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage error={error.message} />;
  }

  if (!invoices.length) {
    return <Alert msg={t("noInvoices")} />;
  }

  const transformedInvoices = invoices.map(transformInvoice).map((invoice) => {
    return {
      ...invoice,
      downloadLink: `${edpApiUrl}/customers/${selectedEdpCustomer.id}/invoices/${invoice.invoiceNr}.pdf`,
    };
  });

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <InvoicesComponent invoices={transformedInvoices} />
    </div>
  );
}

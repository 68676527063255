import { H, Section } from "@jfrk/react-heading-levels";
import { Button, withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Contract.module.css";

export default withComponentDefaults(Invoice, "invoice");

Invoice.propTypes = {
  item: PropTypes.shape({
    address: PropTypes.string,
    area: PropTypes.node,
    contractId: PropTypes.node,
    id: PropTypes.node,
    noticePeriod: PropTypes.node,
    period: PropTypes.node,
    rent: PropTypes.node,
    type: PropTypes.string,
    url: PropTypes.string,
  }),
  styles: PropTypes.objectOf(PropTypes.string),
};

function Invoice({ item, styles = defaultStyles, ...restProps }) {
  const { t } = useTranslation();

  return (
    <Section>
      <div className={clsx(styles.component)} {...restProps}>
        <H className={clsx(styles.title)}>
          {t("contractLabel")} {item.contractId}
        </H>
        <p className={clsx(styles.name)}>{`${item.type}, ${item.address}`}</p>
        <div className={clsx(styles.list)}>
          <div className={clsx(styles.content)}>
            <div>
              <p className={clsx(styles.label)}>{t("contractObjectIdLabel")}</p>
              <p className={clsx(styles.value)}>{item.id}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>
                {t("contractObjectTypeLabel")}
              </p>
              <p className={clsx(styles.value)}>{item.type}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>{t("contractPeriodLabel")}</p>
              <p className={clsx(styles.value)}>{item.period}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>
                {t("contractNoticePeriodLabel")}
              </p>
              <p className={clsx(styles.value)}>{item.noticePeriod}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>{t("contractRentLabel")}</p>
              <p className={clsx(styles.value)}>{item.rent}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>{t("contractAreaLabel")}</p>
              <p className={clsx(styles.value)}>{item.area}</p>
            </div>
          </div>
          <Button to={`${item.url}`} className={clsx(styles.button)}>
            {t("cancelContractLabel")}
          </Button>
        </div>
      </div>
    </Section>
  );
}

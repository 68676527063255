import { graphql, useStaticQuery } from "gatsby";

export default function useStrings() {
  return useStaticQuery(graphql`
    query WP_Strings {
      wp {
        ...WP_StringsForHook
      }
    }
  `)?.wp?.acfOptionsThemeOptions?.strings;
}

// import AccountChoosingForm from "@whitespace/gatsby-plugin-smorgasbord-edp/src/components/AccountChoosingForm";
import Logo from "@whitespace/gatsby-theme-smorgasbord/src/components/Logo";
import userContext from "@whitespace/gatsby-theme-smorgasbord/src/contexts/userContext";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {
  useContext,
  // useEffect,
} from "react";

import { useDialogContext } from "../contexts/dialogContext";

import CustomerNumberLoginForm from "./CustomerNumberLoginForm";
import EmailConfirmationForm from "./EmailConfirmationForm";
import * as defaultStyles from "./LoginDialog.module.css";
import StartScreen from "./StartScreen";

DialogWrapper.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function DialogWrapper({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    // user,
    pending,
  } = useContext(userContext);
  const {
    state: {
      showStartScreen,
      showCustomerNumberLoginForm,
      showEmailConfirmationForm,
      // showAccountChoosingForm,
    },
    // dispatch,
  } = useDialogContext();

  // useEffect(() => {
  //   // No edpCustomer was set and there are multiple EDP accounts associated with the user
  //   // so let's show the account picker
  //   if (!user?.edpCustomer && user?.allEdpCustomers?.length) {
  //     dispatch({ action: "SHOW_ACCOUNT_CHOOSING_FORM" });
  //   }
  // }, [JSON.stringify(user), JSON.stringify(user?.allEdpCustomers)]);

  if (pending) {
    return null;
  }

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.wrapper)}>
        <div className={clsx(styles.box)}>
          <div className={clsx(styles.logo)}>
            <Logo />
          </div>
          {showStartScreen && <StartScreen />}
          {showCustomerNumberLoginForm && <CustomerNumberLoginForm />}
          {showEmailConfirmationForm && <EmailConfirmationForm />}
          {/* {showAccountChoosingForm && <AccountChoosingForm />} */}
        </div>
      </div>
    </div>
  );
}

import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./SmorgasbordHelloModule.module.css";

SmorgasbordHelloModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordHelloModule({
  styles = defaultStyles,
  className,
  title,
  ...restProps
}) {
  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      {"HELLO"}
    </ModuleWrapper>
  );
}

import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./TableModule.module.css";

TableModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modTableOptions: PropTypes.shape({
      modTable: PropTypes.string,
    }),
  }),
};

export default function TableModule({
  className,
  module = {},
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const { modTableOptions: { modTable } = {} } = module;
  const tableData = JSON.parse(modTable);
  const hasTableHead = tableData[0][0].length > 0;

  return (
    <ModuleWrapper className={clsx(styles.component, className)} {...restProps}>
      <table className={clsx(styles.table)}>
        {title && <caption className={clsx(styles.title)}>{title}</caption>}
        {hasTableHead && (
          <thead className={clsx(styles.head)}>
            <tr className={clsx(styles.headTr)}>
              {tableData[0].map((title, index) => {
                return (
                  <th className={clsx(styles.th)} scope="col" key={index}>
                    <HTML>{title}</HTML>
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className={clsx(styles.body)}>
          {tableData.slice(1).map((item, index) => {
            return (
              <tr className={clsx(styles.tr)} key={index}>
                {item.map((data, index) => {
                  return (
                    <td className={clsx(styles.td)} key={index}>
                      <HTML>{data}</HTML>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </ModuleWrapper>
  );
}

import { H } from "@jfrk/react-heading-levels";
import { withComponentDefaults } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./SectionHeader.module.css";

SectionHeader.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  children: PropTypes.node,
};

export default withComponentDefaults(SectionHeader, "sectionHeader");

function SectionHeader({
  className,
  styles = defaultStyles,
  title,
  children,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <H className={clsx(styles.title)}>
        <HTML>{title}</HTML>
      </H>
      {children}
    </div>
  );
}

export default function restructureCustomerData() {
  return {
    control: (provided, state) => {
      const borderColor = state.isFocused
        ? "var(--invoices-select-style-control-focused-border-color, blue)"
        : "var(--invoices-select-style-control-border-color, #E5E5E5)";
      const boxShadow = state.isFocused
        ? "var(--invoices-select-style-control-focused-box-shadow, 0 0 0 3px blue)"
        : "var(--invoices-select-style-control-box-shadow, 0)";
      return {
        ...provided,
        borderColor: borderColor,
        backgroundColor:
          "var(--invoices-select-style-control-background-color, #F6F6F6)",
        fontSize: "var(--invoices-select-style-control-font-size, 0.875rem)",
        borderRadius:
          "var(--invoices-select-style-control-border-radius, 0.25rem)",
        boxShadow: boxShadow,
        "&:hover": {
          cursor: "pointer",
        },
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        color: "var(--invoices-select-style-dropdown-indicator-color, #000)",
        "&:hover": {
          color:
            "var(--invoices-select-style-dropdown-indicator-hover-color, #000)",
        },
      };
    },
    indicatorSeparator: () => {
      return {
        display: "var(--invoices-select-style-indicator-separator, none)",
      };
    },
    option: (provided, state) => {
      const backgroundColor = state.isSelected
        ? "var(--invoices-select-style-option-selected-background-color, #1e3a8a)"
        : "var(--invoices-select-style-option-background-color, #ffffff)";
      const color = state.isSelected
        ? "var(--invoices-select-style-option-selected-color, #ffffff)"
        : "var(--invoices-select-style-option-color, #000000)";

      return {
        ...provided,
        borderBottom:
          "var(--invoices-select-style-option-border-bottom, 1px solid #E5E7EB)",
        backgroundColor: backgroundColor,
        color: color,
        "&:hover": {
          cursor: "pointer",
        },
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
}

import { Section } from "@jfrk/react-heading-levels";
import { Link, RoundIcon, withComponentDefaults } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Article.module.css";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import PageSection from "../../../components/PageSection";
import SectionHeader from "../../../components/SectionHeader";

export default withComponentDefaults(Article, "article");

Article.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

function Article({ className, styles = defaultStyles, ...restProps }) {
  const { t } = useTranslation();
  const {
    contentNode: { title, isFrontPage },
  } = usePageContext();

  return (
    <article className={clsx(className)} {...restProps}>
      {!isFrontPage && (
        <PageSection className={clsx(styles.sectionTop)}>
          {!isFrontPage && (
            <div className={clsx(styles.sectionTopContent)}>
              {!isFrontPage && (
                <Link to={"/"} className={clsx(styles.sectionTopLink)}>
                  <RoundIcon
                    name="chevron-left"
                    className={clsx(styles.roundIcon)}
                  />
                  {t("toFrontPage")}
                </Link>
              )}
              <SectionHeader title={isFrontPage ? null : title}>
                {/* */}
              </SectionHeader>
            </div>
          )}
        </PageSection>
      )}
      <PageSection>
        <Section>
          <ArticleBody />
        </Section>
      </PageSection>
    </article>
  );
}

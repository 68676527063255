import MustacheDataProvider from "@whitespace/gatsby-theme-wordpress-basic/src/components/MustacheDataProvider.js";
import { useStaticQuery, graphql } from "gatsby";
import { camelCase } from "lodash/fp";
import { React, useCallback } from "react";

import useLocalStorage from "./hooks/useLocalStorage";
import municipalityContext from "./municipalityContext";

export default function RootElementWrapper({ children }) {
  const { Provider } = municipalityContext;
  const [selectedMunicipalityId, setSelectedMunicipalityId] =
    useLocalStorage("municipality");
  let municipalities = useStaticQuery(graphql`
    query NsvaMunicipalities {
      wp {
        acfOptionsThemeOptions {
          municipalities {
            allMunicipalities {
              label
              value
              name: label
              id: value
              apiNamespace: value
            }
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions.municipalities.allMunicipalities;

  const selectedMunicipality =
    municipalities.find(
      (municipality) => municipality.id === selectedMunicipalityId,
    ) || municipalities[0];

  const selectMunicipality = useCallback((municipalityId) => {
    setSelectedMunicipalityId(municipalityId);
  }, []);

  let value = {
    municipalities,
    error: null,
    pending: false,
    selectMunicipality,
    selectedMunicipality,
  };

  let allMunicipalities = municipalities;
  let municipality = selectedMunicipality?.id;

  return (
    <Provider value={value}>
      {!!process.env.GATSBY_DEBUG_CONTEXT && (
        <details>
          <summary>
            <code>municipalityContext</code>
          </summary>
          <pre>
            <code>{JSON.stringify(value, null, 2)}</code>
          </pre>
        </details>
      )}
      <MustacheDataProvider
        data={{
          municipalities,
          allMunicipalities,
          municipality,
          [camelCase(`municipialityIs ${municipality}`)]: true,
        }}
      >
        {children}
      </MustacheDataProvider>
    </Provider>
  );
}

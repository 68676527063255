import {
  Alert,
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import edpServiceContext from "../edpServiceContext.js";
import useEdpApi from "../useEdpApi";

import SubscriptionEvent from "./SubscriptionEvent";
import * as defaultStyles from "./SubscriptionEvents.module.css";

SubscriptionEvents.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function SubscriptionEvents({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const { selectedEdpService } = useContext(edpServiceContext);
  const { fetch } = useEdpApi();
  const [events, setEvents] = useState([]);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(async () => {
    if (fetch && selectedEdpService && !pending) {
      setPending(true);
      try {
        const response = await fetch(
          `/services/${selectedEdpService.id}/events`,
        );
        const { data } = await response.json();
        setEvents(data);
      } catch (error) {
        let { message } = error;
        setError(message);
      }
      setPending(false);
    }
  }, [selectedEdpService, fetch]);

  if (pending) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!events.length) {
    return <Alert msg={t("noSubscriptionUsage")} />;
  }

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <ul>
        {events.map((event, index) => (
          <li key={index}>
            <SubscriptionEvent event={event} />
          </li>
        ))}
      </ul>
    </div>
  );
}

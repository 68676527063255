import Logo from "@whitespace/gatsby-theme-smorgasbord/src/components/Logo";
import clsx from "clsx";
import React from "react";

import * as defaultStyles from "./Dialog.module.css";

export default function Dialog({
  styles = defaultStyles,
  className,
  children,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.wrapper)}>
        <div className={clsx(styles.box)}>
          <div className={clsx(styles.logo)}>
            <Logo />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

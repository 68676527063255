// extracted by mini-css-extract-plugin
export var btn = "NoticeRegistration-module--btn--8aQa+";
export var buttonContainer = "NoticeRegistration-module--buttonContainer--ttxz2";
export var component = "NoticeRegistration-module--component--B-wQW";
export var form = "NoticeRegistration-module--form--IcYJD";
export var formWrapper = "NoticeRegistration-module--formWrapper--9TSmi";
export var info = "NoticeRegistration-module--info--qIDrq";
export var input = "NoticeRegistration-module--input--rsIVc";
export var inputDescription = "NoticeRegistration-module--inputDescription--Wd2aX";
export var inputSection = "NoticeRegistration-module--inputSection--7cWq7";
export var link = "NoticeRegistration-module--link--dIVxz";
export var loader = "NoticeRegistration-module--loader--UHlBs";
export var roundIcon = "NoticeRegistration-module--roundIcon--p66eX";
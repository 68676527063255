import { parseISO, format } from "date-fns";

export default function transformInvoice(invoice) {
  const {
    ocrNr,
    renmainingAmount,
    invoiceExpirationDate,
    paymentStatus,
    ...rest
  } = invoice;

  let status;
  switch (paymentStatus) {
    case "Obetald":
      status = 0;
      break;
    case "Helt betald":
      status = 1;
      break;
  }

  return {
    name: `Faktura ${invoice.invoiceNr}`,
    ocr: ocrNr,
    status,
    dueDate: format(parseISO(invoiceExpirationDate), "yyyy-MM-dd"),
    toPay: renmainingAmount,
    ...rest,
  };
}

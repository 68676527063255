/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useTicker } from "@whitespace/gatsby-theme-smorgasbord/src/hooks";
import { MustacheDataProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useEffect, useState, useContext } from "react";

import edpBuildingContext from "./edpBuildingContext";
import edpCustomerContext from "./edpCustomerContext";
import useEdpApi from "./useEdpApi";

export default function EdpBuildingProvider({
  pollInterval = 20 * 60 * 1000, // 20 minutes
  children,
}) {
  const { edpApiUrl } = useEdpApi();
  const { Provider } = edpBuildingContext;

  const { selectedEdpCustomer } = useContext(edpCustomerContext);

  const [pending, setPending] = useState(true);
  const [error, setError] = useState(null);
  const [edpBuildings, setEdpBuildings] = useState([]);
  const [selectedEdpBuildingId, setSelectedEdpBuildingId] = useState();

  const selectedEdpBuilding =
    edpBuildings.find(
      (edpBuilding) => edpBuilding.id === selectedEdpBuildingId,
    ) || edpBuildings[0];

  const selectEdpBuilding = (edpBuildingId) => {
    setSelectedEdpBuildingId(edpBuildingId);
  };

  const tick = useTicker(pollInterval);

  useEffect(async () => {
    setError(null);
    if (selectedEdpCustomer?.id) {
      setPending(true);
      try {
        const response = await fetch(
          `${edpApiUrl}/customers/${selectedEdpCustomer.id}/buildings`,
        );
        if (response.ok) {
          const { data } = await response.json();
          let edpBuildings = (data || []).map((edpBuilding) => ({
            ...edpBuilding,
            userIsAdmin: edpBuilding.adminSsn === selectedEdpCustomer.id,
          }));
          setEdpBuildings(edpBuildings);
        } else {
          // console.log(response);
          throw new Error(response.statusText);
        }
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    } else {
      setEdpBuildings([]);
    }
    setPending(false);
  }, [selectedEdpCustomer?.id, tick]);

  let hasEdpBuildings = !!edpBuildings?.length;
  let isEdpBuilding = hasEdpBuildings; // Alias for hasEdpBuildings
  let edpBuildingProviderErrored =
    !!error || (!pending && !!selectedEdpCustomer && !hasEdpBuildings);

  return (
    <Provider
      value={{
        edpBuildings,
        error,
        pending,
        selectEdpBuilding,
        selectedEdpBuilding,
      }}
    >
      {!!process.env.GATSBY_DEBUG_CONTEXT && (
        <details>
          <summary>
            <code>EdpBuildingProvider</code>
          </summary>
          <pre>
            <code>
              {JSON.stringify(
                {
                  error,
                  pending,
                  edpBuildingsCount: edpBuildings.length,
                  selectedEdpBuilding,
                  edpBuildings,
                },
                null,
                2,
              )}
            </code>
          </pre>
        </details>
      )}
      <MustacheDataProvider
        data={{
          isEdpBuilding,
          hasEdpBuildings,
          edpBuildings,
          selectedEdpBuilding,
          edpBuildingProviderErrored,
        }}
      >
        {children}
      </MustacheDataProvider>
    </Provider>
  );
}

import useThemeProps from "@whitespace/gatsby-theme-smorgasbord/src/useThemeProps";
import React, { useMemo } from "react";

import svaabApiContext from "./svaabApiContext";

export default function SvaabApiProvider(props) {
  props = useThemeProps({ props, name: "SmorgasbordSvaabApiProvider" });
  const { children, url = "/api/svaab" } = props;
  const { Provider } = svaabApiContext;

  const fetch = useMemo(() => {
    return url
      ? async (path, options) => {
          const response = await window.fetch(`${url}${path}`, options);
          if (response.ok) {
            return response;
          } else {
            throw new Error(response.statusText);
          }
        }
      : null;
  }, [url]);

  const post = useMemo(() => {
    return url
      ? async (path, body, options) => {
          options = {
            method: "post",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" },
            ...options,
          };

          return fetch(path, options);
        }
      : null;
  }, [url]);

  return (
    <Provider
      value={{
        svaabApiUrl: url,
        fetch,
        post,
      }}
    >
      {children}
    </Provider>
  );
}

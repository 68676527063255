/** @jsx jsx */
import { jsx } from "@emotion/react";
import {
  useTicker,
  useUserContext,
} from "@whitespace/gatsby-theme-smorgasbord/src/hooks";
import useThemeProps from "@whitespace/gatsby-theme-smorgasbord/src/useThemeProps";
import { MustacheDataProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useEffect, useState } from "react";

import svaabCustomerContext from "./svaabCustomerContext";
import useSvaabApi from "./useSvaabApi";

export default function SvaabCustomerProvider(props) {
  props = useThemeProps({ props, name: "SmorgasbordSvaabCustomerProvider" });
  const { svaabApiUrl } = useSvaabApi();
  let {
    pollInterval = 20 * 60 * 1000, // 20 minutes
    meterId: meterIdFromEdp = null,
    meterReadingEnabled = true,
    children,
  } = props;
  const { Provider } = svaabCustomerContext;

  const { user } = useUserContext();

  const [pending, setPending] = useState(true);
  const [error, setError] = useState(null);
  const [svaabCustomers, setSvaabCustomers] = useState([]);
  const [selectedMeterId, setSelectedMeterId] = useState();

  const selectedSvaabCustomer =
    ((meterIdFromEdp || selectedMeterId) &&
      svaabCustomers.find((svaabCustomer) => {
        return (
          svaabCustomer.meterID &&
          svaabCustomer.meterID.replace(/\D/g, "") ===
            (meterIdFromEdp || selectedMeterId).replace(/\D/g, "")
        );
      })) ||
    svaabCustomers[0];

  const selectSvaabCustomer = (meterID) => {
    setSelectedMeterId(meterID);
  };

  const tick = useTicker(pollInterval);

  useEffect(async () => {
    if (user?.personalNumber && svaabApiUrl) {
      setPending(true);
      try {
        const response = await fetch(`${svaabApiUrl}/customers`);
        if (response.ok) {
          const { data } = await response.json();
          setSvaabCustomers(data);
        } else {
          // console.log(response);
          //throw new Error(response.statusText);
        }
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    } else {
      setSvaabCustomers([]);
    }
    setPending(false);
  }, [user?.personalNumber, tick, svaabApiUrl]);

  let hasSvaabCustomers = !!svaabCustomers && svaabCustomers.length > 0;
  let isSvaabCustomer = hasSvaabCustomers; // Alias for hasSvaabCustomers
  let svaabCustomerProviderErrored = !!error;
  let svaabCustomer = selectedSvaabCustomer;

  return (
    <Provider
      value={{
        svaabCustomers,
        svaabCustomer,
        error,
        pending,
        selectSvaabCustomer,
        selectedSvaabCustomer,
      }}
    >
      <MustacheDataProvider
        data={{
          isSvaabCustomer,
          hasSvaabCustomers,
          svaabCustomers,
          svaabCustomer,
          selectedSvaabCustomer,
          svaabCustomerProviderErrored,
          meterReadingEnabled,
        }}
      >
        {!!process.env.GATSBY_DEBUG_CONTEXT && (
          <details>
            <summary>
              <code>SvaabCustomerProvider</code>
            </summary>
            <pre>
              <code>
                {JSON.stringify(
                  {
                    svaabCustomers,
                    svaabCustomer,
                    error,
                    pending,
                    selectSvaabCustomer,
                    selectedSvaabCustomer,
                  },
                  null,
                  2,
                )}
              </code>
            </pre>
          </details>
        )}
        {children}
      </MustacheDataProvider>
    </Provider>
  );
}

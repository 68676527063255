import edpServiceContext from "@whitespace/gatsby-plugin-smorgasbord-edp/src/edpServiceContext";
import { useContext } from "react";

import municipalityContext from "./src/municipalityContext";

export const useThemeDefinition = () => {
  const { selectedEdpService } = useContext(edpServiceContext);
  const { selectedMunicipality } = useContext(municipalityContext);
  return {
    components: {
      SmorgasbordSvaabCustomerProvider: {
        defaultProps: {
          meterId: selectedEdpService?.meterNumber || null,
          meterReadingEnabled: selectedMunicipality?.value !== 'astorp',
        },
      },
    },
  };
};

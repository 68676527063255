// extracted by mini-css-extract-plugin
export var component = "Invoices-module--component--xrYAZ";
export var currentBalance = "Invoices-module--currentBalance--1WyVU";
export var currentInvoice = "Invoices-module--currentInvoice--sWflC";
export var filter = "Invoices-module--filter--iE4yD";
export var label = "Invoices-module--label--z1YRu";
export var previousInvoices = "Invoices-module--previousInvoices--st7xN";
export var results = "Invoices-module--results--AxOiP";
export var resultsContent = "Invoices-module--resultsContent--t-ggp";
export var resultsHeader = "Invoices-module--resultsHeader--AH03K";
export var resultsTitle = "Invoices-module--resultsTitle--NnUJp";
export var select = "Invoices-module--select--hB6tc";
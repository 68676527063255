import { useCallback, useEffect, useState } from "react";

export default function useAsync(asyncFunction, deps) {
  const immediate = !!deps;
  const [pending, setPanding] = useState(immediate);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  const execute = useCallback((...args) => {
    setPanding(true);
    setValue(null);
    setError(null);
    return asyncFunction(...args)
      .then((response) => {
        setValue(response);
        setPanding(false);
      })
      .catch((error) => {
        setError(error);
        setPanding(false);
      });
  }, deps || []);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
}

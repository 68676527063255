import { Button } from "@whitespace/components";
import {
  Alert,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import svaabCustomerContext from "../svaabCustomerContext.js";
import useSvaabApi from "../useSvaabApi.js";

import * as defaultStyles from "./NoticeRegistration.module.css";

NoticeRegistration.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function NoticeRegistration({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const svaapApi = useSvaabApi();
  const { t } = useTranslation();
  const { selectedSvaabCustomer } = useContext(svaabCustomerContext);
  const [loading, setLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [responseInfo, setResponseInfo] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  useEffect(async () => {
    if (selectedSvaabCustomer) {
      setLoading(true);
      const { data } = await (
        await svaapApi.fetch(`/reg/${selectedSvaabCustomer.id}`)
      ).json();
      setMobile(data.m?.[0]);
      setEmail(data.e?.[0]);
      setLoading(false);
    }
  }, [selectedSvaabCustomer]);

  const submit = async (register) => {
    setPostLoading(true);
    try {
      const res = await svaapApi.post(`/reg/${selectedSvaabCustomer.id}`, {
        mobile: mobile,
        email: email,
        unregister: !register,
      });
      const json = await res.json();
      const responseData = json.data;
      if (responseData && responseData.status === 1 && !responseData.info) {
        responseData.info = register
          ? t("noticeInfoRegistration.registrationSuccessful")
          : t("noticeInfoRegistration.deregistrationSuccessful");
      }
      setResponseInfo(responseData);
    } catch (error) {
      let { message } = error;
      setResponseInfo({ info: message, status: "error" });
    }
    setPostLoading(false);
  };

  if (loading) {
    return <Loading text={t("loadingText")} className={styles.loader} />;
  }

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {postLoading && <Loading text={t("saving")} className={styles.loader} />}
      {responseInfo && (
        <Alert
          status={
            responseInfo.status !== "error"
              ? responseInfo.status === 1
                ? "success"
                : "info"
              : "error"
          }
          msg={responseInfo.info}
        />
      )}
      <div className={styles.formWrapper}>
        <div className={clsx(styles.inputSection)}>
          <label htmlFor="notice-info-registration-sms">
            {t("noticeInfoRegistration.sms")}:
          </label>
          <input
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className={clsx(styles.input)}
            id="notice-info-registration-sms"
          />
        </div>
        <div className={clsx(styles.inputSection)}>
          <label htmlFor="notice-info-registration-mail">
            {t("noticeInfoRegistration.mail")}:
          </label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={clsx(styles.input)}
            id="notice-info-registration-mail"
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          className={styles.btn}
          onClick={() => submit(true)}
        >
          {t("noticeInfoRegistration.register")}
        </Button>
        <Button
          type="submit"
          className={styles.btn}
          onClick={() => submit(false)}
        >
          {t("noticeInfoRegistration.deregister")}
        </Button>
      </div>
    </div>
  );
}

/** @jsx jsx */
import { jsx } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import edpBuildingContext from "@whitespace/gatsby-plugin-smorgasbord-edp/src/edpBuildingContext.js";
import edpServiceContext from "@whitespace/gatsby-plugin-smorgasbord-edp/src/edpServiceContext";
import svaabCustomerContext from "@whitespace/gatsby-plugin-smorgasbord-svaab/src/svaabCustomerContext";
import {
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useContext } from "react";

SmorgasbordServiceSelectorModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordServiceSelectorModule({
  styles = {},
  className,
  title,
  ...restProps
}) {
  const {
    edpBuildings,
    selectedEdpBuilding,
    selectEdpBuilding,
    pending: loadingBuildings,
    error: errorBuildings,
  } = useContext(edpBuildingContext);
  const { edpServices, selectedEdpService, selectEdpService } =
    useContext(edpServiceContext);

  const {
    svaabCustomers,
    selectedSvaabCustomer,
    selectSvaabCustomer,
    pending: loadingCustomers,
    error: errorCustomers,
  } = useContext(svaabCustomerContext);

  return (
    (edpServices?.length > 0 ?
      <ModuleWrapper
        title={title}
        {...restProps}
        className={clsx(styles.component, className)}
      >
        {loadingBuildings ? (
          <Loading />
        ) : errorBuildings ? (
          <ErrorMessage error={errorBuildings.message} />
        ) : (
          <span>
            <label htmlFor="building-selector" className={styles.label}>
              För{" "}
            </label>
            {edpBuildings.length === 1 ? (
              selectedEdpBuilding.address
            ) : (
              <select
                placeholder="Byt anläggning"
                value={selectedEdpBuilding?.id || ""}
                onChange={(event) => {
                  selectEdpBuilding(event.target.value);
                }}
              >
                {edpBuildings.map(({ id, address }) => (
                  <option key={id} value={id}>
                    {address}
                  </option>
                ))}
              </select>
            )}
            {". "}
            {edpServices.length === 1 ? (
              selectedEdpService.description
            ) : (
              <select
                placeholder="Byt tjänst"
                value={selectedEdpService?.id || ""}
                onChange={(event) => {
                  selectEdpService(event.target.value);
                }}
              >
                {edpServices.map(({ id, description }) => (
                  <option key={id} value={id}>
                    {description}
                  </option>
                ))}
              </select>
            )}
          </span>
        )}
      </ModuleWrapper>
      : svaabCustomers.length > 0 ?
      <ModuleWrapper
        title={title}
        {...restProps}
        className={clsx(styles.component, className)}
      >
        {loadingCustomers ? (
          <Loading />
        ) : errorCustomers ? (
          <ErrorMessage error={errorCustomers.message} />
        ) : (
          <span>
            <label htmlFor="customer-selector" className={styles.label}>
              För mätare{" "}
            </label>
            {svaabCustomerContext.length === 1 ? (
              selectedSvaabCustomer.meterID
            ) : (
              <select
                placeholder="Byt mätare"
                value={selectedSvaabCustomer?.meterID || ""}
                onChange={(event) => {
                  selectSvaabCustomer(event.target.value);
                }}
              >
                {svaabCustomers.map(({ meterID }) => (
                  <option key={meterID} value={meterID}>
                    {meterID}
                  </option>
                ))}
              </select>
            )}
          </span>
        )}
      </ModuleWrapper>
    : null)

  );
}

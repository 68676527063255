// extracted by mini-css-extract-plugin
export var component = "Alert-module--component--yudTf";
export var error = "Alert-module--error--T4+Md";
export var icon = "Alert-module--icon--af8IP";
export var info = "Alert-module--info--HkWoG";
export var success = "Alert-module--success--0qMA-";
export var textError = "Alert-module--textError--Znc7d";
export var textInfo = "Alert-module--textInfo--HbORw";
export var textSuccess = "Alert-module--textSuccess--ZCqjS";
export var textWarning = "Alert-module--textWarning--jBRG7";
export var warning = "Alert-module--warning--kKUXc";
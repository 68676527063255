import { H, Section } from "@jfrk/react-heading-levels";
import {
  Link,
  Icon,
  withComponentDefaults,
  Button,
} from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Invoice.module.css";

export default withComponentDefaults(Invoice, "invoice");

Invoice.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  item: PropTypes.object,
  showDownloadLink: PropTypes.bool,
};

function Invoice({
  className,
  styles = defaultStyles,
  item,
  showDownloadLink = true,
  ...restProps
}) {
  const { t } = useTranslation();

  const statuses = {
    0: t("unpaidLabel"),
    1: t("paidLabel"),
    2: t("latePaymentLabel"),
  };

  return (
    <Section>
      <div className={clsx(styles.component, className)} {...restProps}>
        <H className={clsx(styles.title)}>{item.name}</H>
        <span className={clsx(styles.status, styles[`status${item.status}`])}>
          {statuses[item.status]}
        </span>
        <div className={clsx(styles.list)}>
          <div className={clsx(styles.content)}>
            <div>
              <p className={clsx(styles.label)}>{t("dueDateLabel")}</p>
              <p className={clsx(styles.value)}>{item.dueDate}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>{t("ocrLabel")}</p>
              <p className={clsx(styles.value)}>{item.ocr}</p>
            </div>
            <div>
              <p className={clsx(styles.label)}>{t("toPayLabel")}</p>
              <p className={clsx(styles.value)}>{item.toPay}</p>
            </div>
          </div>
          {showDownloadLink && item.downloadLink && (
            <Link
              to={`${item.downloadLink}`}
              className={clsx(styles.link)}
              download
              type="external"
            >
              <Icon name={"download"} className={clsx(styles.icon)} />
              <span>{t("downloadAviLabel")}</span>
            </Link>
          )}
          {showDownloadLink && item.downloadFunction && (
            <Button
              onClick={item.downloadFunction}
              className={clsx(styles.button)}
            >
              {t("downloadAviLabel")}
            </Button>
          )}
        </div>
      </div>
    </Section>
  );
}

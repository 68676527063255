import { jsx } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import {
  Button,
  Link,
  RoundIcon,
  FormInputField,
} from "@whitespace/components";
import {
  ErrorMessage,
  Loading,
  Alert,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import edpCustomerContext from "../edpCustomerContext.js";
import useEdpApi from "../useEdpApi.js";

import * as defaultStyles from "./UpdateCustomerInfo.module.css";

UpdateCustomerInfo.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function UpdateCustomerInfo({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const { selectedEdpCustomer, triggerFetch } = useContext(edpCustomerContext);
  const { edpApiUrl } = useEdpApi();
  const [showEditFields, setShowEditFields] = useState(false);
  const [responseData, setResponseData] = useState(undefined);

  const handleSubmit = async (values) => {
    const updateResponse = await fetch(
      `${edpApiUrl}/customers/${selectedEdpCustomer.id}/information`,
      {
        method: "post",
        body: JSON.stringify(values),
        headers: { "Content-Type": "application/json" },
      },
    );

    if (updateResponse.ok) {
      triggerFetch();
      toggleShowEditFields();
    }
    setResponseData(updateResponse);
  };

  const toggleShowEditFields = () => {
    setShowEditFields(!showEditFields);
  };

  const validate = (values) => {
    const errors = {};

    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t("invalidEmail");
    }

    return errors;
  };

  return (
    <>
      {selectedEdpCustomer && (
        <div className={styles.formWrapper}>
          {responseData && (
            <Alert
              status={responseData.ok ? "success" : "error"}
              msg={responseData.ok ? t("infoSaved") : t("couldNotUpdate")}
            />
          )}
          {true && (
            <Formik
              initialValues={{
                mobile: selectedEdpCustomer.mobile,
                email: selectedEdpCustomer.email,
              }}
              onSubmit={handleSubmit}
              validate={validate}
            >
              <Form className={styles.form}>
                <FormInputField
                  className={styles.formInput}
                  name="mobile"
                  type="string"
                  label={t("phoneNumber")}
                />
                <FormInputField
                  className={styles.formInput}
                  name="email"
                  type="string"
                  label={t("emailAddress")}
                />
                <Button type="submit" className={styles.btn}>
                  {t("saveChanges")}
                </Button>
              </Form>
            </Formik>
          )}
        </div>
      )}
    </>
  );
}

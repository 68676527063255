/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Icon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";

import * as defaultStyles from "./ErrorMessage.module.css";

ErrorMessage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  severity: PropTypes.string,
};

function getBGColor(severity, styles) {
  switch (severity) {
    case "error":
      return styles.error;
    case "warning":
      return styles.warning;
    default:
      return styles.info;
  }
}

function getTextColor(severity, styles) {
  switch (severity) {
    case "error":
      return styles.textError;
    case "warning":
      return styles.textWarning;
    default:
      return styles.textInfo;
  }
}

function getIconColor(severity) {
  switch (severity) {
    case "error":
      return "#ef5350";
    case "warning":
      return "#ff9800";
    default:
      return "#03a9f4";
  }
}

export default function ErrorMessage({
  error,
  severity = "error",
  styles = defaultStyles,
  className,
  ...restProps
}) {
  if (!error) {
    return null;
  }
  return (
    <div
      className={clsx(
        styles.component,
        getBGColor(severity, styles),
        className,
      )}
      {...restProps}
    >
      <Icon
        name={severity}
        color={getIconColor(severity)}
        className={styles.icon}
        css={css`
          flex-shrink: 0;
          align-self: start;
        `}
      />
      <p className={getTextColor(severity, styles)}>{error}</p>
    </div>
  );
}

import { H, Section } from "@jfrk/react-heading-levels";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./SubscriptionEvent.module.css";

SubscriptionEvent.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  event: PropTypes.shape({
    date: PropTypes.string,
    meterNumber: PropTypes.string,
    preliminaryYearlyconsumption: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default function SubscriptionEvent({
  styles = defaultStyles,
  className,
  event: { date, meterNumber, preliminaryYearlyconsumption, text, type, value },
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <Section>
      <div className={clsx(styles.component, className)} {...restProps}>
        <H className={clsx(styles.title)}>{text}</H>
        <p className={clsx(styles.type)}>{type}</p>
        <dl>
          <dt>{t("date")}</dt>
          <dd>{format(parseISO(date), "yyyy-MM-dd")}</dd>

          <dt>{t("meterNumber")}</dt>
          <dd>{meterNumber}</dd>

          <dt>{t("yearlyConsumption")}</dt>
          <dd>{preliminaryYearlyconsumption}</dd>

          <dt>{t("value")}</dt>
          <dd>{value}</dd>
        </dl>
      </div>
    </Section>
  );
}

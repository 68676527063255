import useThemeProps from "@whitespace/gatsby-theme-smorgasbord/src/useThemeProps";
import React, { useMemo } from "react";

import edpApiContext from "./edpApiContext";

export default function EdpApiProvider(props) {
  props = useThemeProps({ props, name: "SmorgasbordEdpApiProvider" });
  const { children, url = "/api/edp" } = props;
  const { Provider } = edpApiContext;

  const fetch = useMemo(() => {
    return url
      ? async (path, options) => {
          const response = await window.fetch(`${url}${path}`, options);
          if (response.ok) {
            return response;
          } else {
            throw new Error(response.statusText);
          }
        }
      : null;
  }, [url]);

  return (
    <Provider
      value={{
        edpApiUrl: url,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
}

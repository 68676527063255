import { ErrorMessage } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-smorgasbord/src/components/LoginDialog.module.css";
import LoginDialogButtons from "@whitespace/gatsby-theme-smorgasbord/src/components/LoginDialogButtons";
import clsx from "clsx";
import { camelCase } from "lodash";
import PropTypes from "prop-types";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MunicipalitySelector from "./MunicipalitySelector";

StartScreen.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function StartScreen({ styles = defaultStyles }) {
  const { t } = useTranslation();
  const [error, setError] = useState();
  useEffect(() => {
    let url = new URL(window.location.href);
    let { error, ...otherParams } = qs.parse(url.search.substring(1));
    if (error) {
      setError(error);
      let search = qs.stringify(otherParams);
      url.search = search ? `?${search}` : "";
      window.history.replaceState(null, "", url.href);
    }
    // window.location.search = `?${qs.stringify(otherParams)}`;
  }, []);

  return (
    <>
      <h1 className={clsx(styles.headline)}>Logga in</h1>
      {/* <p className={clsx(styles.description)}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p> */}
      {!!error && (
        <ErrorMessage
          error={
            t([
              `grandid.error.${camelCase(error || "unknown")}`,
              `grandid.error.unknown`,
            ]) + (process.env.GATSBY_DEBUG_LOGIN && error ? ` (${error})` : "")
          }
        />
      )}
      <MunicipalitySelector />
      <LoginDialogButtons />
      <p className={clsx(styles.alert)}>
        Lämna aldrig ut dina inloggningsuppgifter och logga aldrig in på
        uppmaning av någon som kontaktar dig.
      </p>
    </>
  );
}

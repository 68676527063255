import { Link, RoundIcon, Button } from "@whitespace/components";
import {
  Alert,
  ErrorMessage,
  Loading,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import svaabCustomerContext from "../svaabCustomerContext.js";
import useSvaabApi from "../useSvaabApi.js";

import * as defaultStyles from "./MeterChange.module.css";

MeterChange.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function MeterChange({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [responseInfo, setResponseInfo] = useState("");
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);

  const { selectedSvaabCustomer, pending: loadingCustomer } =
    useContext(svaabCustomerContext);

  const { fetch, post } = useSvaabApi();

  const handleSubmit = async (values) => {
    const { time } = values;
    if (data.idu && time) {
      setPending(true);
      try {
        const res = await post(`/booking/${data.idu}`, {
          plandate: time,
        });
        const json = await res.json();
        if (json?.success) {
          setResponseInfo(json.data);
        } else {
          setError("Uppdatering misslyckades");
        }
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    }
    setPending(false);
  };

  const handleBack = async () => {
    setShowTimeSlots(false);
  };

  const handleShowTimeSlots = () => {
    setShowTimeSlots(true);
    setResponseInfo("");
  };

  useEffect(async () => {
    if (fetch && !pending && !showTimeSlots && selectedSvaabCustomer) {
      setPending(true);
      try {
        const response = await fetch(
          `/booking/${selectedSvaabCustomer.meterID}`,
        );
        const { data } = await response.json();
        setData(data);
      } catch (error) {
        let { message } = error;
        setError({ message });
      }
    }
    setPending(false);
  }, [fetch, selectedSvaabCustomer, showTimeSlots]);

  if (pending || loadingCustomer) {
    return (
      <div className={clsx(styles.component, className)} {...restProps}>
        <Loading />
      </div>
    );
  }

  if (!data) {
    return <Alert msg={t("noCurrentBookings")} />;
  }

  if (!data?.sel?.length) {
    return <Alert msg={t("noBookingsAvailable")} />;
  }

  if (error) {
    return <ErrorMessage error={error} severity="error" />;
  }

  const { sel = [] } = data;

  if (showTimeSlots) {
    return (
      <div>
        {responseInfo && (
          <Alert
            status={
              responseInfo.status !== "error"
                ? responseInfo.status === 1
                  ? "success"
                  : "info"
                : "error"
            }
            msg={responseInfo.info}
          />
        )}
        {pending && <Loading text={t("saving")} className={styles.loader} />}
        <Formik initialValues={{ timeSlots: sel }} onSubmit={handleSubmit}>
          {({ values: { timeSlots } }) => (
            <Form>
              <div className={styles.timeSlotsWrapper}>
                <div className={styles.timeSlotsHeader}>
                  <h3>{t("availableSlots")}</h3>
                </div>
                <div className={styles.timeSlotsMain}>
                  <ul>
                    {timeSlots.map((time, index) => (
                      <li key={index}>
                        <Field
                          type="radio"
                          name="time"
                          id={time}
                          value={time}
                        />
                        <label htmlFor={time}>{time}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={styles.timeSlotsFooter}>
                <Link onClick={handleBack} className={styles.link}>
                  <RoundIcon name="chevron-left" className={styles.backBtn} />
                  {t("previous")}
                </Link>
                <Button type="submit" className={styles.submitBtn}>
                  {t("confirmRescheduleTime")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {pending ? (
        <Loading text={t("retrievingBooking")} />
      ) : (
        <>
          <div className={styles.info}>
            <p>
              {t("bookedMeterChange")} <b>{data?.data}</b>
            </p>
          </div>
          <Link onClick={handleShowTimeSlots} className={styles.link}>
            {t("rescheduleTimeForMeterChange")}
            <RoundIcon name="chevron-left" className={styles.roundIcon} />
          </Link>
        </>
      )}
    </div>
  );
}

import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./Section.module.css";

PageSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function PageSection({
  children,
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div
        className={clsx(
          styles.wrapper,
          layout.component,
          layout.componentWidthNarrow,
        )}
      >
        {children}
      </div>
    </div>
  );
}

import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { DashboardMenu } from "@whitespace/components";
import edpBuildingContext from "@whitespace/gatsby-plugin-smorgasbord-edp/src/edpBuildingContext";
import edpServiceContext from "@whitespace/gatsby-plugin-smorgasbord-edp/src/edpServiceContext";
import { Loading } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import useNunjucks from "@whitespace/gatsby-theme-smorgasbord/src/hooks/useNunjucks";
import { useMustacheData } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getPage } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
// import Nunjucks from "nunjucks";
import PropTypes from "prop-types";
import React, { useContext } from "react";

MenuModule.propTypes = {
  className: PropTypes.string,
  module: PropTypes.shape({
    menu: PropTypes.shape({ contacts: PropTypes.array }),
  }),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.node,
  transformMenuItem: PropTypes.func,
};

// const PANELS_SVAAB = ["rescheduleMeterChange", "meterReading"];
// const PANELS_EDP = ["serviceUsage", "invoices", "subscriptionEvents"];

function defaultTransformMenuItem(menuItem, { pages, mustacheData, nunjucks }) {
  let { connectedNode, label, description, url, target, extra, ...rest } =
    menuItem;

  let visibility = connectedNode?.node?.modSmorgasbord?.visibility;

  if (visibility) {
    let result;
    try {
      // Backwards compatibility with Mustache renderer
      visibility = visibility.replace(/\{\{/g, "").replace(/\}\}/g, "");
      result = JSON.parse(
        nunjucks.renderString(
          `{{ "true" if ${visibility} else "false" }}`,
          mustacheData,
        ),
      );
    } catch (error) {
      // Do nothing
    }
    if (!result) {
      return null;
    }
  }

  let icon = extra?.icon || rest?.icon;
  if (typeof icon === "string") {
    icon = { name: icon };
  }

  let { contentType: { node: { name: type = "custom" } = {} } = {}, id } =
    connectedNode?.node || {};

  let content = type === "page" ? getPage(pages, id) : {};

  return {
    type,
    url,
    target: connectedNode?.node?.id ? target : "_blank",
    ...content,
    label,
    description: description || (content && content.description),
    ...extra,
    ...rest,
    icon,
  };
}

export default function MenuModule({
  module = {},
  title,
  transformMenuItem = defaultTransformMenuItem,
  ...restProps
}) {
  const pages = usePages();
  const { menu } = module;
  let mustacheData = useMustacheData();
  const { nunjucks } = useNunjucks();
  const { pending: loadingBuildings } = useContext(edpBuildingContext);
  const { pending: loadingServices } = useContext(edpServiceContext);

  let menuItems = menu?.node?.menuItems?.nodes?.map((node) =>
    transformMenuItem(node, { pages, mustacheData, nunjucks }),
  );

  menuItems = menuItems.filter(Boolean);

  return (
    <ModuleWrapper title={title} {...restProps}>
      {loadingBuildings || loadingServices ? (
        <Loading />
      ) : (
        <DashboardMenu items={menuItems} />
      )}
    </ModuleWrapper>
  );
}

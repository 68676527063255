// import PropTypes from 'prop-types';
import React from "react";

Logo.propTypes = {};

export default function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 227 48">
      <path
        fill="#00A1D4"
        d="M170.17 34.69c-.46 0-.8-.29-.91-.75 0-.05-.17-.51-.35-1.08l-.85-2.4c-.69-1.95-1.66-4.46-2.75-7.2-2.11-5.49-4.57-11.95-6.22-16.17a276.84 276.84 0 0 0-1.83-4.69c-.17-.51.05-1.03.51-1.26.52-.17 1.03.06 1.26.52 0 0 8.34 21.48 11.14 29.14l1.2-3.2c1.14-3.09 2.69-6.97 4.17-10.8l5.95-15.09c.17-.51.74-.74 1.25-.51.52.17.75.74.52 1.26 0 0-2.23 5.71-4.8 12.23-2.57 6.57-5.43 14-6.75 17.48-.34.97-.62 1.72-.62 1.83-.06.4-.46.69-.92.69Zm42.34 10.68s-9.88-23.08-12.85-30.46a4689.9 4689.9 0 0 0-6.06 14.34c-3.43 8.12-6.86 16.12-6.86 16.12-.23.46-.74.69-1.25.52a1.02 1.02 0 0 1-.52-1.26l1.66-3.89c1.03-2.4 2.4-5.6 3.83-9.08 2.91-6.92 6.23-14.63 7.6-18l.68-1.6a.96.96 0 0 1 1.03-.63c.4.06.74.34.86.74a9.97 9.97 0 0 0 .28.8l.92 2.29c.8 1.88 1.88 4.51 3.08 7.37 2.4 5.71 5.32 12.51 7.2 17.03a503.4 503.4 0 0 0 2.12 5.03c.23.45 0 1.02-.52 1.25-.11.06-.22.06-.4.06a1.07 1.07 0 0 1-.8-.63Zm-119.88.46a.96.96 0 0 1-.97-.97V18.1c0-2.68-1.03-4.4-2.69-5.6-1.71-1.2-4.17-1.42-7.08-1.42-3.38 0-5.83.28-7.43 1.42-1.55 1.2-2.35 2.86-2.4 5.6v26.8c0 .52-.4.98-.97.98a.96.96 0 0 1-.98-.98V18.1c0-3.08 1.09-5.6 3.15-7.14 2.05-1.54 4.97-1.83 8.57-1.83 3.14 0 6.06.29 8.17 1.83 2.17 1.49 3.54 3.94 3.49 7.14v25.83h35.6c1.88 0 5.2-.05 8.51-.8 3.37-.74 6.69-2.17 8.8-4.68a10.62 10.62 0 0 0 2.29-6.97c0-4.12-1.78-6.4-4.12-7.95-2.34-1.54-5.43-2.17-7.71-2.57-.92-.17-3.43-.63-5.95-1.03-2.51-.4-4.97-.85-5.82-1.03-1.15-.28-2.23-.51-3.2-.91a4.88 4.88 0 0 1-2.4-1.89 4.32 4.32 0 0 1-.58-2.11c0-1.14.35-2.17.98-2.97a5.8 5.8 0 0 1 2.45-1.72c1.89-.8 4.29-.97 6.57-.97h11.43c.52 0 .97.4.97.97 0 .52-.4.98-.97.98h-11.43c-1.6 0-3.25.11-4.57.45-1.37.35-2.4.86-2.91 1.55-.34.45-.57.97-.57 1.77 0 .51.11.85.28 1.14.17.29.46.51.8.74.75.46 2.06.8 3.55 1.15.8.17 3.25.57 5.77 1.02 2.51.4 5.03.86 6 1.03 2.34.46 5.65 1.09 8.4 2.86 2.8 1.77 5.03 4.8 5.03 9.54 0 3.43-1.03 6.17-2.69 8.17a14.41 14.41 0 0 1-6.29 4.29c-4.68 1.83-9.94 1.89-12.51 1.89l-36.57-.06Z"
      />
      <path
        fill="#0064A6"
        d="m223.31 45.66-17.65-38.8a5.03 5.03 0 0 0-1.95-2.17 7.33 7.33 0 0 0-3.88-.98c-1.72 0-2.97.4-3.94.98a4.99 4.99 0 0 0-1.95 2.17L178.4 41.37a8.87 8.87 0 0 1-8.11 5.14 8.7 8.7 0 0 1-8.12-5.08l-16-37.72H129.1c-3.09 0-6.4.35-9.26 1.32-2.86.97-5.2 2.51-6.63 4.8a9.58 9.58 0 0 0-1.37 4.86c0 2 .51 3.48 1.26 4.68a9.25 9.25 0 0 0 3.08 2.86c2.52 1.48 5.6 2.06 7.43 2.4.91.17 3.43.57 5.94.97 2.52.4 4.97.8 5.89.97.86.17 1.83.34 2.8.74a5.5 5.5 0 0 1 2.74 2.18 5.55 5.55 0 0 1-1.03 6.69 9.03 9.03 0 0 1-3.54 1.93c-2.51.8-5.2.86-6.51.92h-27.32a1.6 1.6 0 0 1-1.6-1.6V15.94c-.11-4.45-1.48-7.31-3.77-9.25-2.34-1.95-5.89-2.98-10.63-2.98h-9.31c-5.09 0-8.63 1.32-10.86 3.38-2.23 2.05-3.43 5.02-3.54 8.8v28.28a1.6 1.6 0 1 1-3.2 0V15.9c.05-4.46 1.54-8.35 4.57-11.15C67.26 2 71.6.51 77.26.51h9.31c5.14 0 9.49 1.09 12.63 3.66 3.14 2.57 4.86 6.57 4.97 11.66v20h25.72c.85 0 2.45-.06 4-.34 1.54-.29 3.08-.86 3.82-1.6.52-.52.75-.98.75-1.78 0-.45-.12-.74-.23-.97-.12-.23-.34-.4-.69-.63a8.17 8.17 0 0 0-2.74-.85h-.06c-.74-.17-3.25-.57-5.77-.97-2.51-.4-5.03-.8-6.06-.98-1.42-.28-3.71-.68-6.05-1.65-2.35-.97-4.86-2.46-6.52-5.03a12.66 12.66 0 0 1 .06-12.97c1.94-3.15 5.03-5.03 8.29-6.12A33.34 33.34 0 0 1 128.9.46h18.18c.62 0 1.2.4 1.48.97l16.4 38.68a5.66 5.66 0 0 0 5.2 3.2 5.66 5.66 0 0 0 5.2-3.2v-.05l15.6-34.57a8.06 8.06 0 0 1 3.2-3.6 10.57 10.57 0 0 1 5.6-1.43c2.23 0 4.12.51 5.6 1.43a8.05 8.05 0 0 1 3.2 3.6l17.66 38.8c.34.8 0 1.71-.8 2.11a1.5 1.5 0 0 1-.63.17 1.39 1.39 0 0 1-1.49-.91ZM31.5 1.89c9.36 3.48 14.56 10.91 15.7 19.48 1.2 9.6-3.31 20.92-13.54 19.2-5.89-.97-9.83-9.88-5.26-15.37 4.57-5.49 12.17-14.23 3.09-23.31Z"
      />
      <path
        fill="#BDE3F2"
        d="M.8 28.51C-.86 18.63 2.91 10.4 9.83 5.14c7.71-5.83 19.77-7.6 23.43 2.17 2.05 5.6-3.66 13.49-10.69 12.23-7.03-1.2-18.46-3.43-21.77 8.97Z"
      />
      <path
        fill="#00A1D4"
        d="M39.2 42.06c-7.71 6.34-16.74 7.2-24.74 3.83C5.54 42.17-2 32.57 4.63 24.57 8.4 20 18.1 21.03 20.57 27.71c2.46 6.69 6.23 17.66 18.63 14.35Z"
      />
    </svg>
  );
}
